.ir-ws-create-case {
  margin-top: 5px;
  margin-inline: 20px;
  margin-bottom: 10px;
}

@media (min-width:1024px) {
  .ir-ws-create-case {
    // margin-top: 5px;
    // margin-inline: 20px;
    // margin-bottom: 10px;
    margin: 12px;
  }
}

@media (min-width:1280px) {
  .ir-ws-create-case {
    // margin-top: 5px;
    // margin-inline: 20px;
    // margin-bottom: 10px;
    margin: 12px;
  }
}
@media (min-width:1700px) {
  .ir-ws-create-case {
    // margin-top: 5px;
    // margin-inline: 20px;
    // margin-bottom: 10px;
    margin: 20px;
  }
}