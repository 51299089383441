.ir-ws--create-webinar-submit-button {
  cursor: pointer;
}
input[type="datetime-local"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type="datetime-local"]:focus::-webkit-datetime-edit {
  color: #0b2f8a !important;
}

input[type="text"]:required:invalid::-webkit-input-placeholder {
  color: transparent;
}
input[type="text"]:required:focus::-webkit-input-placeholder {
  color: #0b2f8a !important;
}

.ir-ws-create-case-file-btn-container {
  .ir-ws-create-btn-container {
    cursor: default;
    .ir-ws-create-course-add-file-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 52px;
      border-radius: 5px;
      font-size: 14px;
      i {
        margin-right: 5px;
      }
      // span {
      //   font-size: 14px;
      // }
    }
  }
}
.ir-ws-create-btn-container {
  // margin-top: 16px;
  // animation: slideInUp; /* referring directly to the animation's @keyframe declaration */
  // animation-duration: 4s;
  cursor: pointer;
  .ir-ws-banner-btn {
    cursor: pointer;
    width: 320px;
    border-radius: 5px;
    padding: 15px 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.2px;
    position: relative;
    background-size: 400%;
    height: 52px;
    width: 50%;
    background-size: 400%;
    background-color: #6842ef;
    border: none;
    display: inline-block;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: 0 50%;
      width: 100%;
      height: inherit;
      border-radius: inherit;
      background: rgb(43, 167, 255);
      background: linear-gradient(
        90deg,
        rgba(43, 167, 255, 1) 0%,
        rgba(104, 66, 239, 1) 100%
      );
      transition: all 0.8s;
    }

    &:hover::before {
      transform: scaleX(1);
    }

    &:hover {
      text-decoration: none;
    }

    span {
      position: relative;
      z-index: 1;
      padding-top: 3px;
    }
  }
}

@media (min-width:1024px) {
  .ir-ws-create-btn-container .ir-ws-banner-btn {
    height: 36px;
    padding: 0px;
    width: 40%;
    font-size: 10px;
  }
}

@media (min-width:1280px) {
  .ir-ws-create-btn-container .ir-ws-banner-btn {
    height: 50px;
    padding: 0px;
    width: 45%;
    font-size: 12px;
  }
}

@media (min-width:1700px) {
  .ir-ws-create-btn-container .ir-ws-banner-btn {
    height: 54px;
    padding: 0px;
    width: 50%;
    font-size: 14px;
  }
}