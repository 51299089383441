.app-sidebar {
  width: 300px;
  --webkit-box-orient: vertical;
  --webkit-box-direction: normal;
  --ms-flex-direction: column;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  // padding: 1.5rem 10px;
  display: var(--webkit-box);
  display: var(--ms-flexbox);
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background-color: #fff;

  .tab-content {
    height: 100%;

    .ir-tab-heading {
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      color: #252b42;
    }

    > .tab-pane {
      --webkit-box-orient: vertical;
      --webkit-box-direction: normal;
      --ms-flex-direction: column;
      flex-direction: column;
      padding: 1.5rem 15px;

      &#profile,
      &#settings {
        padding: 0;
      }
    }
    > .active {
      display: flex;
    }
  }

  .tab-pane {
    height: 100%;
  }

  .input-icon-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    // margin-bottom: 15px;

    .form-control {
      padding: 0.4rem 1rem;
      padding-right: 2.5rem;
      font-size: 12px;
      border-radius: 10px;

      &:focus {
        color: #212529;
        background-color: #fff;
        border-color: #b2a7d1;
        outline: 0;
        -webkit-box-shadow: 0 0 0 1px #6842ef;
        box-shadow: 0 0 0 1px #6842ef;
      }
    }

    i {
      position: absolute;
      right: 1rem;
      opacity: 0.4;
      pointer-events: none;
      cursor: pointer;
      z-index: 1;
      font-size: 14px;

      &:hover {
        color: red;
      }
    }
  }

  .tab-content-scroll {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 1.5rem;
    padding-right: 15px;
  }

  .list-group-item {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    // background-color: rgba(70, 186, 147, 0.2);
    border-radius: 10px;
    border: none;
    margin-bottom: 8px;

    &:hover {
      background-color: rgba($color: #6842ef, $alpha: 0.2);
    }

    &.chat-list-side-active {
      background-color: rgba($color: #6842ef, $alpha: 0.2);
    }
  }

  .avatar {
    display: inline-block;
    margin-bottom: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;

    // &.ir-chat-list-state-status {
    //     &:before {
    //         content: "";
    //         position: absolute;
    //         display: block;
    //         width: 12px;
    //         height: 12px;
    //         border-radius: 50%;
    //         bottom: 50%;
    //         left: -3px;
    //         transform: translateY(0);
    //     }

    //     &.ir-chat-list-state-active {
    //         &:before {
    //             background-color: #47bd94;
    //         }
    //     }

    //     // &.ir-chat-list-state-archieve {
    //     //     &::before {
    //     //         // background-color: #f2ab03;
    //     //         content: 'e897';
    //     //         font-family: 'Material Symbols Outlined';
    //     //     }
    //     // }
    // }

    > img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      border: 1px solid #e1dafd;
    }

    .new {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.4);
      top: 5px;
      left: 35px;
      margin-top: -12px;

      span {
        font-family: Poppins-Bold;
        font-size: 10px;
        color: #fff;
      }
    }
  }

  .ir-side-list-content {
    --webkit-box-flex: 1;
    --webkit-flex: 1;
    --moz-box-flex: 1;
    --ms-flex: 1;
    flex: 1;
    position: relative;
    min-width: 0;
    display: var(--webkit-box);
    display: var(--webkit-flex);
    display: var(--moz-box);
    display: var(--ms-flexbox);
    display: flex;
    align-items: center;
    justify-content: center;

    div:first-child {
      min-width: 0;
      display: flex;
      --webkit-box-orient: vertical;
      --webkit-box-direction: normal;
      --webkit-flex-direction: column;
      --moz-box-orient: vertical;
      --moz-box-direction: normal;
      --ms-flex-direction: column;
      flex-direction: column;
      --webkit-box-flex: 1;
      --webkit-flex: 1;
      --moz-box-flex: 1;
      --ms-flex: 1;
      flex: 1;
      padding-right: 10px;

      .ir-room-title-container {
        font-size: 15px;
        display: flex;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #252b42;
        margin: 0;
        text-transform: capitalize;
        line-height: 20px;
        // padding-left: 10px;

        .ir-chat-archive-lock {
          margin-left: 10px;
        }
        .ir-room-title {
          font-size: 12px;
          margin: 0;
        }
      }

      p {
        font-size: 11px;
        padding-left: 10px;

        &.ir-ws-chat-sidebar-content {
          padding-left: 10px;
        }
      }
    }

    .ir-chat-list-time {
      font-size: 12px;
    }
  }

  .ir-create-case-btn {
    height: 50px;
    // font-size: 16px;

    &:before {
      height: 50px;
    }
  }
}

.ir-case-form-container {
  padding: 2.5rem 1.5rem;
  position: relative;
  flex: 1 1 auto;

  .ir-login-row {
    position: relative;
    width: 100%;
    border-bottom: 2px solid #dbdbdb;
    margin-bottom: 25px;

    &.ir-signup-flname {
      width: 50%;

      &.ir-signup-lname {
        margin-left: 20px;
      }
    }

    .ir-login-input-field {
      display: block;
      width: 100%;
      height: 50px;
      background: transparent;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      color: #555555;
      line-height: 1.2;
      padding: 0 2px;
      outline: none;
      border: none;

      &.ir-login-textarea {
        height: 75px;
      }

      &:focus {
        border-color: transparent !important;
      }
    }

    .ir-sign-focus-input {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;

      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
        background: rgb(27, 54, 100);
        background: linear-gradient(
          360deg,
          rgba(27, 54, 100, 1) 0%,
          rgba(71, 189, 148, 1) 100%
        );
      }
    }

    .ir-login-input-field:focus + .ir-sign-focus-input::before {
      width: 100%;
    }
  }

  .btn {
    border: 0;
  }

  .btn:disabled {
    border: 0;
  }
}

.ir-images-row {
  display: flex;
  flex-wrap: wrap;
  // max-height: 100px;
  overflow: hidden;
  overflow-y: scroll;
  // padding-bottom: 15px;

  .ir-image-col {
    display: block;
    margin: 5px;
    border: 1px solid #ccc;
    position: relative;
    width:70px;
    .images {
      border: 1px solid #6842ef;
    }
    .deletedImages {
      border: 1px solid #6842ef;
    }
    a {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 22px;
      height: 22px;
      cursor: pointer;
      // background:  #6842ef;
      background-color: rgba($color: #6842ef, $alpha: 0.5);
      text-align: center;
      line-height: 22px;
      color: #fff;
      border-radius: 22px;
    }
  }
}

.user-profile-img {
  position: relative;

  .profile-img {
    height: 150px;
    object-fit: cover;
    width: 100%;
  }

  .overlay-content {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 10%,
      transparent 60%,
      rgba(0, 0, 0, 0.5)
    );
    bottom: 0;
    color: hsla(0, 0%, 100%, 0.6);
    content: "";
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    h5 {
      font-size: 16px;
      font-weight: 600;
      padding: 1.5rem 10px;
    }
  }
}

.ir-side-profile-container {
  padding: 0.5rem 1.5rem;
  text-align: center;
  margin-top: -3rem;
  position: relative;
  border-bottom: 1px solid #eaeaf1;

  .avatar-lg {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    background-color: #fafafa;
    border: 1px solid #f6f6f9;
    padding: 0.25rem;
    max-width: 100%;
  }

  h5 {
    font-size: 20px;
    text-transform: capitalize;
    color: #252b42;
  }

  p {
    font-size: 14px;
  }
}

.ir-profile-desc {
  padding-top: 0.5rem !important;
  .ir-prof-details {
    font-size: 15px;
    color: rgb(73, 80, 87);
    margin-left: 4px;
  }

  .avatar-xs {
    height: 1.8rem;
    width: 1.8rem;
  }

  .avatar-title {
    align-items: center;
    background-color: rgba(78, 172, 109, 0.2);
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    width: 100%;

    i {
      color: #47bd94;
    }
  }
}
.profile-user {
  display: inline-block;
  position: relative;

  .profile-photo-edit {
    bottom: 0;
    cursor: pointer;
    left: auto;
    position: absolute;
    right: 0;

    .profile-img-file-input {
      display: none;
    }

    .profile-photo-edit {
      bottom: 0;
      cursor: pointer;
      left: auto;
      position: absolute;
      right: -10px;
    }

    .avatar-title {
      align-items: center;
      border: 1px solid rgba(78, 172, 109, 0.4) !important;
      color: #fff;
      display: flex;
      font-weight: 500;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }

  .avatar-xs {
    height: 1.8rem;
    width: 1.8rem;
  }
}

.ir-set-prof-content-container {
  justify-content: space-between;
  margin-bottom: 5px;
  .ir-set-prof-info-container {
    width: 100%;
    .ir-set-prof-info-title-container {
      font-size: 13px;
      font-weight: 600;
    }

    .ir-set-prof-info-content-container {
      color: #495057;
      border: 0;
      border-bottom: 1px solid #6842ef;
      background-color: #fff;
      width: 90%;
      padding: 0;
      border-radius: 0;
      font-size: 13px;

      &:disabled {
        border: 0;
      }

      &:focus {
        outline: none;
        border: 0;
        border-bottom: 1px solid #6842ef;
      }
    }
  }

  .ir-set-prof-info-icon-container {
    cursor: pointer;
    i {
      color: #6842ef;
      font-size: 11px;
      // background-color: rgba(78, 172, 109, 0.1);
      padding: 6px 8px;
      border-radius: 5px;

      &:hover {
        background-color: #6842ef;
        color: #fff;
      }
    }
  }
}

p.profile-bg-replace {
  margin: 0;
  line-height: 35px;
  background-color: #6842ef;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #ffffff;
  font-family: Poppins-Bold;
  display: inline-block;
  width: 75px;
  height: 75px;
  font-size: 20px;
  margin: 0;
  line-height: 70px;
}

.ir-share-icon-copy-link {
  width: 48px;
  height: 48px;
  background: #6842ef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    color: #fff;
    font-size: 30px;
  }
}

.ir-update-prof-pic-onupdate {
  max-width: 100%;
}

.imageEditModalOpened {
  display: none;
}

.ir-modal-edit-img-case {
  &.iks-mint-modal-container {
    .FIE_topbar-center-options {
      order: 2;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .FIE_topbar-close-button {
      font-size: 0 !important;
      font-family: Poppins-Bold;
      padding: 0;
      position: relative;
      width: 100px;

      svg {
        visibility: hidden;
      }

      &:before {
        position: absolute;
        content: "Cancel";
        color: #768184;
        width: 100px;
        font-size: 14px;
        left: -10px;
        font-family: Poppins-Medium;
      }

      &:hover {
        &:before {
          color: #6842ef;
        }
      }
    }
  }
}

.ir-chat-list-state-archive-lock {
  display: none;
}

.ir-chat-list-state-archieve-lock-display {
  display: block;

  span {
    font-size: 16px;
    padding-left: 10px;
    opacity: 0.6;
  }
}

.ir-accept-terms-container {
  margin: 20px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  .ir-checkbox {
    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    label {
      position: relative;
      cursor: pointer;
      margin: 0;
      font-size: 12px;
      color: #5c5c5c;

      &:before {
        content: "";
        --webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #6842ef;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 6px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
        border-radius: 3px;
      }
    }

    input:checked + label:before {
      background-color: #6842ef;
    }

    input:checked + label:after {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    a {
      margin-left: 5px;
      color: #6842ef;
      font-size: 13px;
    }
  }
}

.ir-ws-share-main-container {
  .ir-share-modal-inside-content {
    .ir-share-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .ir-share-icon-container {
        margin-right: 15px;
      }
      .ir-share-icon-container-hover {
        transition: transform 0.3s ease;
        &:hover {
          transform: scale(1.2);
        }
        .ir-share-icon-link {
          i {
            line-height: 0;
          }
        }
      }
    }
  }
}

.ir-ws-chat-create-case-cont {
  padding: 0 50px;
}

.ir-ws-chat-create-case-image-editor {
  height: calc(100% - 30px);
  label {
    margin: 0px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
  .FIE_topbar-save-button .SfxButton-Label::before {
    content: "Done"; /* Replace "Save" with "Done" */
  }

  .FIE_topbar-save-button .SfxButton-Label {
    visibility: hidden; /* Hide the original text */
    position: relative;
  }

  .FIE_topbar-save-button .SfxButton-Label::before {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }

  .FIE_topbar-zoom-out-btn {
    svg {
      width: 14px !important;
      height: 14px !important;
    }
  }

  .FIE_topbar-zoom-in-btn {
    svg {
      width: 14px !important;
      height: 14px !important;
    }
  }
  .SfxSelect-Container {
    display: none;
  }
  .SfxInput-root {
    display: none;
  }

  .FIE_text-tool-options {
    .sc-qvjmv1-1 {
      display: none;
    }
  }
  // .sc-qvjmv1-1 {
  //   display: none;
  // }
  .sc-dwg14p-0 {
    .sc-dwg14p-1 {
      .iirfpj {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .sc-qvjmv1-0 {
    // display: none;
  }
  .kBJBhJ[aria-selected="true"] {
    background: #cdbfff !important;
  }

  .kBJBhJ:hover {
    background: #cdbfff !important;
  }
  .cSszUr[aria-selected="true"] {
    background: #cdbfff !important;
  }
  .cSszUr:hover {
    background: #cdbfff !important;
  }
  .epDskT {
    background-color: #6842ef !important;
  }
  .oWOcv {
    background-color: #6842ef !important;
  }

  .sc-21g986-0 {
    // justify-content: center;
    // gap: 30px;
  }
  // .sc-m9ezm7-0 {
  //   position: fixed;
  //   bottom: 25px;
  //   right: 44%;
  // }
  .sc-dwg14p-1 {
    padding: 0px;
    // padding-bottom: 30px;
  }

  .sc-qvjmv1-1 {
    div:nth-child(1),
    div:nth-child(3) {
      display: none;
    }
  }
  .sc-dwg14p-1 {
    ul li:nth-child(2),
    ul li:nth-child(3),
    ul li:nth-child(4),
    ul li:nth-child(5) {
      display: none;
    }

    ul {
      li {
        label {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.ir-ws-chat-case-search-cont{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
  margin-bottom: 15px;
  position: relative;
  .input-icon-group{
    width: 100%;
  }
}
.ir-ws-chat-toggle-ph-opt{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 130px;
  // background:#f5f5f5;
  background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
gap:10px;
padding: 8px;
border-radius: 10px;
position: absolute;
top:50px;
right: 0px;
z-index: 10;
  .ir-ws-chat-ph-opt{
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 100%;
    gap:10px;
    cursor: pointer;
    font-weight: 600;
    color: #333;

    &:hover{
      .ir-chat-bg-btn{

        background: #6842ef;
      }
    }
  }
}
@media (max-width: 768px) {
  .ir-chat-sidebar-main-container {
    width: 100%;
  }
  .app-sidebar .tab-content > .tab-pane {
    padding: 10px;
  }

  .app-sidebar .tab-content-scroll {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    padding: 0px;
  }

  .app-sidebar .tab-content-scroll::-webkit-scrollbar {
    display: none;
  }

  .app-sidebar .input-icon-group .form-control {
    padding: 20px 10px;
  }
  .app-sidebar .ir-side-list-content div:first-child {
    padding-right: 0px;
  }
  .ir-ws-chat-create-case-cont {
    padding: 0 10px;
  }
}

.create-case-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: #6842ef !important;
  font-weight: 500;
  white-space: nowrap;
}

.loading-text {
  font-size: 16px;
  color: #6842ef !important;
  font-weight: 600;
}

.loading-spinner {
  border: 4px solid transparent;
  border-top: 4px solid #6842ef;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

/* Keyframes for spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 769px) and (max-width: 1279px) {
//   .ir-ws-chat-none-toggle {
//     display: block !important;
// }
// .ir-chat-none-flex-toggle {
//   display: flex !important
// ;
// }
.ir-ws-chat-toggle-ph-opt {
  padding: 6px;
}
.ir-chat-bg-btn {
  width: 26px;
  height: 26px;
  font-size: 12px;
}
.ir-ws-chat-toggle-ph-opt .ir-ws-chat-ph-opt {
  font-size: 10px;
}
  /* Styles that will only apply for screen sizes between 768px and 1280px */
 .ir-ws-side-menu {
display: none;
 }
 .app-sidebar {
  width: 200px;
 }
 .app-sidebar .tab-content > .tab-pane {
  padding: 8px;
 }
 .app-sidebar .list-group-item {
  padding: 4px;
 }
 .app-sidebar .avatar {
  width: 28px;
  height: 28px;
 }
 .app-sidebar .ir-side-list-content div:first-child .ir-room-title-container .ir-room-title {
  font-size: 10px;
 }
 .app-sidebar .ir-side-list-content div:first-child p {
  font-size: 8px;
 }
 .app-sidebar .ir-side-list-content div:first-child .ir-room-title-container {
  line-height: 14px;
 }
 .app-sidebar .tab-content .ir-tab-heading {
  font-size: 14px;
 }
 .app-sidebar .ir-side-list-content div:first-child {
  font-size: 12px;
 }
 .ir-drop-downmenu-trigger-container .ir-dropdown-container ul li {
  font-size: 10px;
  padding: 4px 8px;
 }

 .app-sidebar .input-icon-group .form-control {
font-size: 10px;
padding: 5px 8px;
 }
 .app-sidebar .tab-content-scroll {
  padding-right: 8px;
  padding-bottom: 0px;
 }
 .ir-ws-chat-bar-tab{
  display: block !important;
 }
 .ir-chat-bar-tab-flex{
  display: flex !important;
  // gap:5px
 }
 .ir-found-no-results-text{
font-size: 12px
 }
 .app-sidebar .ir-create-case-btn:before {
height: 36px;
 }

 .ir-chat-quill-cont{
  .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
    height: 18px !important;
  }
  .ql-toolbar.ql-snow {
    padding: 3px !important;
  }
  .ql-container.ql-snow {
    height: 80px !important;
}
 }
}


@media (min-width: 1280px) {
  .app-sidebar .avatar {
    width: 32px;
    height: 32px;
  }
  .app-sidebar .list-group-item {
    padding: 4px;
  }
  .ir-drop-downmenu-trigger-container i{
font-size: 12px;
  }
  .app-sidebar .ir-side-list-content div:first-child p {
    font-size: 10px;
  }
  .app-sidebar .ir-side-list-content div:first-child .ir-room-title-container .ir-room-title {
    font-size: 10px;
  }
}

@media (min-width: 1600px) {
  .app-sidebar .ir-side-list-content div:first-child .ir-room-title-container .ir-room-title {
    font-size: 12px;
  }
  .app-sidebar .ir-side-list-content div:first-child p {
    font-size: 10px;
  }
  .app-sidebar .avatar {
    width: 40px;
    height: 40px;
  }
  .app-sidebar .list-group-item {
    padding: 10px;
  }
  .ir-drop-downmenu-trigger-container i{
    font-size: 18px;
      }
}