.ir-ws-create-course-container {
  margin-top: 5px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.ir-ws-create-course-heading {
  margin-top: 20px;
  font-weight: bold;
  color: #6842ef;
  font-size: 28px;
}
.ir-ws-create-course-container-inputes {
  margin-top: 30px;
  width: 70%;
  display: grid;
  gap: 24px;
  .ir-ws-course-input-title-container {
    display: grid;
    gap: 5px;
  }
}
.ir-ws-course-title-label {
  font-weight: bold;
  font-size: 16px;
  color: #252b42;
}
.ir-ws-course-mandatory-star {
  color: #f03e3e;
}
.ir-ws-course-title-inpute {
  border: 1px solid #ccc;
  &:focus {
    outline: 1px solid #6842ef;
  }
  padding: 15px 50px 15px 15px;
  border-radius: 8px;
}
.ir-ws-course-inpute-latters-container {
  position: relative;

  span {
    font-size: 16px;
    opacity: 50%;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
/* Text Editor */
.ql-container.ql-snow {
  height: 150px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.quill.ir-ws-course-description-input {
  border-radius: 8px;
}
.ql-editor.ql-blank {
  background-color: white;
  border-radius: 8px;
}

/*Dropdwon*/
.ir-ws-course-dropdown-menus {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
// .ir-ws-course-dropdown-selection {
//   height: 50px;
//   border-radius: 10px;
//   outline: none;
//   &:focus {
//     outline: none;
//     border: 2px solid #6842ef; /* border color when selected */
//   }
// }

.ir-ws-select-container {
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  position: relative;
}
.ir-ws-course-dropdown-selection {
  border: 1px solid #ccc;
  appearance: none;
  // padding: 0 30px 0 15px;
  // height: 60px;
  padding-block: 15px;
  padding-left: 16px;
  width: 100%;
  color: #000;
  background-color: #fff;
  font-size: 16px;
  outline: none;
  border-radius: 8px;
  // background-color: #6842ef;
  &:focus {
    outline: none;
    border: 1px solid #6842ef; /* border color when selected */
  }

  option {
    line-height: 40px;
  }
}
.ir-ws-course-dropdown-icon-container {
  width: 50px;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 20px;
  }
}
.ir-ws-course-image-upload {
  background: white;
  height: 230px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgb(189, 189, 189);
}
.ir-ws-course-selected-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .ir-ws-course-selected-video-image-container {
    background: rgb(220, 220, 220);
    width: 300px;
    border-radius: 8px;
    padding-inline: 10px;
    padding-block: 5px;
  }
  p {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
.custom-svg {
  width: 40px;
  height: 40px;
  opacity: 0.5;
  &:hover {
    stroke: #6842ef;
    opacity: 100%;
  }
}
.ir-ws-instructor-input {
  width: 100%;
}
.ir-ws-save-course-btn-container {
  margin-top: 20px;
  margin-bottom: 50px;
  // width: 40%;
}
.ir-ws-course-image-input {
  display: none;
}

.ir-ws-course-title-uploaded-image {
  height: 230px;
  width: 50%;
}
.ir-ws-course-title-uploaded-video {
  height: 230px;
  width: 50%;
}
.ir-ws-course-textarea {
  border: 1px solid #ccc;
  resize: none;
  height: 120px;
}
/* Second Page Css*/

.ir-ws-text-edit-input {
  width: 30%;
  outline: none;
  border: none;
  // padding-left: 20px;
  border-radius: 8px;
  padding: 15px;
  font-size: 1.1rem;
}
.ir-ws-invalid-field-message {
  justify-content: center;
  color: rgb(255, 99, 99);
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}

/*newly added css for points section*/

.ir-ws-course-points {
  width: 100%;
}
.ir-ws-course-input-point-container {
  gap: 20px;
  padding-block: 10px;
  &:last-child {
    padding-bottom: 0;
  }
}
.ir-ws-course-delete-points-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  &:hover {
    fill: #6842ef;
  }
}
.ir-course-selected-image-video-delete-icon {
  cursor: pointer;
  &:hover {
    fill: #6842ef;
  }
}

.ir-ws-course-add-point-btn-container {
  margin: 0;
  width: 200px;
  .ir-ws-course-add-point-btn {
    width: 200px;
  }
}

.ir-ws-course-added-points-container {
  background-color: #b3b3b3;
  width: 75%;
  border-radius: 8px;

  ul {
    padding-block: 15px;
    margin: 0;
    font-weight: 600;
    .ir-ws-course-added-points-delete-btn-container {
      padding-right: 15px;
      padding-block: 15px;
    }
  }
}

.ir-ws-course-promotion-video-helper-text {
  margin: 0;
  padding: 0;
}

////This course Includes styling///////

.ir-ws-create-course-includes-point-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.ir-ws-create-course-includes-checkbox-container {
  display: flex;
  align-items: center;
  gap: 60px;
}
.ir-ws-create-course-input-checkbox-container {
  align-items: center;
  width: 100%;
  gap: 20px;
}
.ir-ws-create-course-checkbox {
  width: 30px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ir-ws-create-course-active-checkbox {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #6842ef;
  cursor: pointer;
}

.ir-ws-create-course-save-btn {
  .ir-ws-banner-btn {
    width: 320px;
  }
}

.ir-ws-create-course-section-btn {
  .ir-ws-banner-btn {
    width: 320px;
  }
}
/////-----------Select tag----------//////////
// .ir-ws-create-course-dropdown-box {
//   background-color: #fff;
//   padding: 15px;
//   border-radius: 10px;
//   justify-content: space-between;
//   gap: 10px;
//   cursor: pointer;
//   border: 1px solid #ccc;
//   width: 330px;
//   transition: all 0.3s;
//   &:hover {
//     border: 1px solid #6842ef;
//   }
//   p {
//     margin: 0;
//   }
// }
// .ir-ws-create-course-category-list {
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   margin-top: 10px;
// }
.ir-ws-create-course-dropdown-box {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  width: 330px;
  transition: all 0.3s;
  &:hover {
    border: 1px solid #6842ef;
  }
  p {
    margin: 0;
  }
}

.ir-ws-create-course-category-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.ir-ws-create-course-category-list.open {
  max-height: 1000px; /* Adjust the height according to your content */
}


@media (min-width:1024px) {
  .ir-ws-create-course-heading {
    font-size: 20px;
    margin-top: 8px;
  }
  .ir-ws-create-course-container {
    margin-left: 12px;
    margin-bottom: 12px;
  }
  .ir-ws-create-course-container-inputes {
    margin-top: 8px
  };
  .ir-ws-course-title-label {
    font-size: 12px;
  }
  .ir-ws-course-title-inpute {
    font-size: 10px;
    padding: 10px 10px 10px 10px;
  }
  .ir-ws-course-inpute-latters-container span {
    font-size: 10px;
  }
  .ir-ws-create-course-container-inputes {
    gap:12px
  }
  .ir-ws-create-course-dropdown-box {
    padding: 10px;
    font-size: 10px;
    width: 180px;
  }
  .ir-ws-create-course-save-btn .ir-ws-banner-btn {
    width: 180px;
  }
  .ir-ws-save-course-btn-container {
    margin-bottom: 8px !important;
  }
  .ql-container.ql-snow {
    height: 120px;
    .ql-editor{
      padding: 12px;
      p{
        font-size: 10px;
      }
    }
  }
  .ir-ws-course-image-upload {
    height: 150px;
  }
  .custom-svg {
    width: 24px;
    height: 24px;
  }
  .ir-ws-course-promotion-video-helper-text {
    font-size: 10px;
  }
  .ir-ws-course-selected-video-container p {
    font-size: 10px;
  }
  .ir-ws-course-selected-video-image-container{
   svg{
     width: 24px;
    height: 24px;
     }
  }
  .ir-course-selected-image-video-delete-icon {
    width: 24px;
    height: 24px;
  }
  .ir-ws-course-promotional-video-uploaded {
    width: 20px !important;
  }
  .ir-ws-create-course-second-page-container {
    margin-top: 8px !important;
  }
  .ir-ws-second-page-section-heading .heading-h {
    font-size: 16px !important;
  }
  .ir-ws-second-page-section-heading .heading-p {
    font-size: 16px !important;
  }
  .ir-ws-editor-svg {
    width: 16px !important;
    height: 16px !important;
  }
    .ir-ws-second-page-section-heading {
      gap:12px !important;
    }
    .ir-ws-text-edit-input {
      font-size: 12px;
      // border-radius: 8px;
      padding: 8px;
    }
    .ir-ws-single-lecture-content {
      padding-top: 10px !important;
      padding-inline: 10px !important;
    }
    .ir-ws-lecture {
    height: 40px !important;
    }
    
.ir-ws-lecture .ir-ws-lecture-paragraph {
  font-size: 12px !important;
  padding-left: 10px !important;
}
.ir-ws-lecture {
  gap:12px !important;
}
.ir-ws-lecture-file-upload-content {
  width: 75px !important;
  height: 75px !important;
  svg{
    width: 24px;
    height: 24px;
  }
}
.ir-ws-uploaded-video-name {
  font-size: 10px;
}
.ir-ws-lecture-file-upload {
  padding: 10px !important;
}
.ir-ws-uploaded-file-name {
  font-size: 10px;
  
}
.ir-ws-uploaded-file-show {
  width: 200px !important;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;    /* Hide overflow */
  text-overflow: ellipsis; /* Show "..." when text overflows */
  svg{
    width: 20px;
    height: 20px;
  }
}

.ir-ws-uploaded-file-show-container {
  .ir-ws-flex {
    .ir-course-selected-image-video-delete-icon{
      width: 40px !important;
      height: 40px !important;
    }
  }
}
.ir-ws-uploaded-file-show-container {
  gap: 0px !important;
}
.ir-ws-lecture-add-button {
  height: 40px !important;
  font-size: 10px !important;
}
.ir-ws-add-lacture-button {
  margin-left: 10px !important;
  margin-block: 10px !important;
  height: 40px !important;
  font-size: 10px !important;
  width: 150px !important;
}
.ir-ws-course-section-error-message {
  font-size: 10px;
  margin-left: 10px !important;
  margin-top: 0px !important;
  
}
.ir-ws-create-course-second-page-container {
  gap: 20px !important;
}
.ir-ws-add-section-button {
  height: 40px !important;
  font-size: 10px !important;
  margin-left: 10px !important;
}
.ir-ws-create-course-section-btn .ir-ws-banner-btn {
  width: 200px;
}
.ir-ws-uploaded-file-show {
  width: 150px !important;
}
.ir-ws-add-section-button {
  width: 150px !important;
}
.ir-ws-invalid-field-message {
  font-size: 10px;
}
}

@media (min-width:1280px) {
  .ir-ws-invalid-field-message {
    font-size: 14px;
  }
  .ir-ws-course-image-upload {
    height: 230px;
  }
  .ir-ws-uploaded-file-show {
    width: 200px !important;
  }
  .ir-ws-add-section-button {
    width: 200px !important;
  }
  .ir-ws-uploaded-file-show {
    width: 300px !important;
  }
  .ir-ws-course-title-label {
    font-size: 14px;
  }
  .ir-ws-course-title-inpute {
    border: 1px solid #ccc;
    padding: 12px;
    font-size: 12px;
}
.ir-ws-create-course-dropdown-box {
  padding: 12px;
  font-size: 12px;
  width: 300px;
}
.ir-ws-select-container {
  gap:30px
}
.ir-ws-create-course-heading {
  font-size: 24px;
}
.ir-ws-create-course-save-btn .ir-ws-banner-btn {
  width: 250px !important;
}
.ir-ws-second-page-section-heading .heading-h {
  font-size: 20px !important;
}
.ir-ws-second-page-section-heading .heading-p {
  font-size: 20px !important;
}
// .ir-ws-editor-svg {
//   width: 16px !important;
//   height: 16px !important;
// }
.ir-ws-single-lecture-content {
  padding-top: 15px !important;
  padding-inline: 15px !important;
}

.ir-ws-lecture {
  height: 50px !important;
  // font-size: 14px !important;
}
.ir-ws-lecture .ir-ws-lecture-paragraph {
  font-size: 14px !important;
}

.ir-ws-lecture-add-button {
  height: 50px !important;
  font-size: 12px !important;
}

.ir-ws-uploaded-video-name {
  font-size: 12px;
}

.ir-ws-uploaded-file-name {
  font-size: 12px;
}
.ir-ws-add-lacture-button {
  height: 50px !important;
  font-size: 12px !important;
  margin-left: 15px !important;
  margin-block: 15px !important;
  width: 200px !important;
}

.ir-ws-add-section-button {
  height: 50px !important;
  font-size: 12px !important;
  margin-left: 15px !important;
}
.ir-ws-create-course-section-btn .ir-ws-banner-btn {
  width: 250px;
}
.ir-ws-course-selected-video-container p {
  font-size: 12px;
}
.ir-ws-course-promotion-video-helper-text {
  font-size: 12px;
}
.ir-ws-create-course-dropdown-box p {
  font-size: 12px;
}
.ir-ws-create-course-container {
  margin-top: 12px !important;
}
.ir-ws-course-inpute-latters-container span {
  font-size: 12px;
}
.ir-ws-lecture-file-upload-content {
  width: 85px !important;
  height: 85px !important;
}
.ir-ws-uploaded-file-show-container {
  gap:10px !important;
}
.ir-ws-lecture-file-upload {
  padding: 12px !important;
}

.ir-ws-course-section-error-message {
  font-size: 12px;
  margin-left: 12px !important;
  margin-top: 0px !important;
}
.ql-container.ql-snow .ql-editor p {
  font-size: 12px;
}
}


@media (min-width:1700px) {
  .ir-ws-text-edit-input {
    font-size: 12px;
    padding: 15px;
}
  .ir-ws-course-section-error-message {
    font-size: 14px;
    margin-left: 20px !important;
    margin-top: 0px !important;
  }
  .ir-ws-lecture-add-button {
    width: 160px !important;
  }
  .ir-ws-lecture-file-upload-content {
    width: 100px !important;
    height: 100px !important;
  }
  .ir-ws-uploaded-file-show-container {
    gap:20px !important;
  }
  .ir-ws-lecture-file-upload {
    padding: 20px !important;
  }
  .ir-ws-course-inpute-latters-container span {
    font-size: 14px;
  }
  .ir-ws-create-course-second-page-container {
    margin-top: 20px !important;
}
  .ir-ws-create-course-container {
    margin-left: 20px;
    margin-bottom: 20px;
}
  .ir-ws-course-textarea {
    height: 150px !important;
  }
  .ir-ws-course-title-label {
    font-size: 16px;
  }
  .ir-ws-course-title-inpute {
    border: 1px solid #ccc;
    padding: 15px;
    font-size: 16px;
}
.ir-ws-create-course-dropdown-box {
  padding: 15px;
  font-size: 16px;
}
.ir-ws-create-course-heading {
  font-size: 28px;
  margin-top: 20px;
}
.ir-ws-create-course-save-btn .ir-ws-banner-btn {
  width: 320px !important;
}
.ir-ws-second-page-section-heading .heading-h {
  font-size: 24px !important;
}
.ir-ws-second-page-section-heading .heading-p {
  font-size: 24px !important;
}

.ir-ws-single-lecture-content {
  padding-top: 20px !important;
  padding-inline: 20px !important;
}

.ir-ws-lecture {
  height: 60px !important;
  // font-size: 14px !important;
}
.ir-ws-lecture .ir-ws-lecture-paragraph {
  font-size: 16px !important;
}

.ir-ws-lecture-add-button {
  height: 60px !important;
  font-size: 16px !important;
}
.ir-ws-uploaded-video-name {
  font-size: 16px;
}

.ir-ws-uploaded-file-name {
  font-size: 16px;
}

.ir-ws-add-lacture-button {
  height: 60px !important;
  font-size: 16px !important;
  margin-left: 20px !important;
  margin-block: 20px !important;
}

.ir-ws-add-section-button {
  height: 60px !important;
  font-size: 16px !important;
  margin-left: 20px !important;
}
.ir-ws-create-course-section-btn .ir-ws-banner-btn {
  width: 320px;
}
.ir-ws-course-selected-video-container p {
  font-size: 16px;
}
.ir-ws-course-promotion-video-helper-text {
  font-size: 16px;
}
.ir-ws-create-course-dropdown-box p {
  font-size: 16px;
}
.ql-container.ql-snow .ql-editor p {
  font-size: 16px;
}
}