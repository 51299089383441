.ir-ws-webinar-list-tab {
  // width: 96%;
  width: calc(100% - 40px);
  // top: 5%;
  // left: 2.5%;
  border-radius: 8px;
  background-color: #fff;
  // margin: 20px 0px 0px 20px;
  // margin: 20px;
  margin: 20px 20px 0 20px;
}
.ir-ws-webinar-list-tab.active {
  height: 100%;

  .ir-ws-admin-webinar-content {
    height: 100%;
    padding-block: 10px;
  }
}
.ir-ws-admin-webinar-title-container {
  display: flex;
  align-items: center;
  width: 50%;
  span {
    margin-left: 20px;
    font-size: 14px;
    // font-family: Poppins-SemiBold;
    font-weight: 600;
    margin-left: 15px;
    margin-bottom: 0;
    width: 20%;
  }
}
.ir-ws-webinar-list-tab:last-child {
  margin-bottom: 20px;
}

.ir-ws-admin-webinar-content {
  width: 100%;
  height: 100%;
  padding-block: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.ir-ws-webinar-transaction-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.ir-ws-webinar-presenter-status {
  padding: 5px 12px;
  font-size: 13px;
  background-color: #6842ef;
  color: #fff;
  border-radius: 8px;
  margin-right: 10px;
}
.ir-ws-admin-list-button-container button {
  width: 135px;
  border: none;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer;
  margin-right: 10px;
  // padding: 10px 15px;
  padding: 10px 0px;
  background-color: #d9d9d9;
  color: #212529;
}
.ir-ws-webinar-transaction-button-active {
  background-color: #6842ef;
  color: #fff;
}

.ir-ws-admin-drop-down-icon {
  height: 15px;
  cursor: pointer;
}
.ir-ws-webinar-title-text {
  font-size: 14px;
  // font-family: Poppins-SemiBold;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 0;
  width: 40%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ir-ws-webinar-date-time-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    width: 200px;
  }
}
.ir-ws-active-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}
.ir-ws-active-status-icon {
  height: 24px;
  width: 24px;
}
.ir-ws-webinar-status-text {
  font-size: 14px;
  // font-family: Poppins-SemiBold;
  font-weight: 600;
  margin-left: 5px;
  margin-bottom: 0;
}
.ir-ws-webinar-list-accordian-tab {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  background: #bfbfbf;
  font-size: 14px;
  margin-top: 20px;
  list-style: none;
}
.ir-ws-list-item-box {
  width: 150px;
  text-align: center;
}
.ir-ws-webinar-list-data {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  background: #efefef;
  // margin-block: 20px;
  font-size: 14px;
  list-style: none;
}
.ir-ws-webinar-list-data li {
  width: 100%;
  text-align: center;
}
.ir-ws-webinar-download-icon {
  height: 25px;
  cursor: pointer;
}
.ir-ws-admin-list-button-container {
  display: flex;
  // gap: 10px;
}

.presernter1-button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ir-ws-webinar-actions-button {
  transition: all 0.3s ease;
  &:hover {
    background: #6842ef;
    color: #fff;
  }
}
.ir4u-copyicons svg {
  width: 1rem;
  height: 1rem;
  margin-left: 3px;
  margin-bottom: 25%;
}
// @media (min-width: 1400px) {
//   .ir-ws-admin-webinar-title-container {
//     width: 30%;
//   }
// }

.chat-room-image-circle {
  width: 30px;
  height: 30px;
  // aspect-ratio: 3/2;
  // object-fit: contain;
  border-radius: 50%;
  margin-left: 15px;
}
.ir-ws-webinar-inovice-icon {
  color: #6842ef;
  fill: #6842ef;
  &:hover {
    fill: #6842ef;
    color: #6842ef;
  }
}

@media (min-width:1024px) {
  .ir-customtabs-child-container .ir-found-no-results-text {
    font-size: 14px !important;
  }
  .chat-room-image-circle {
    width: 24px;
    height: 24px;
  }
  .ir-ws-webinar-title-text {
    font-size: 10px;
    margin-left: 8px;
  }
.ir-ws-custom-tab-button {
  font-size: 12px;

}
.ir-ws-webinar-list-tab {
  margin: 8px 8px 0px 8px;
  width: calc(100% - 16px);
}

.ir-ws-admin-list-button-container button {
  font-size: 8px;
  width: 80px;
  margin-right: 8px;
  padding: 8px 0px;
}
.ir-ws-webinar-list-tab.active .ir-ws-admin-webinar-content {
padding-block: 8px;
}
.ir-ws-admin-webinar-content {
  padding-block: 8px;
}
.chat-room-image-circle {
  margin-left: 8px;
}

.ir-ws-webinar-list-tab:last-child {
  margin-bottom: 8px;
}
.ir-ws-admin-webinar-title-container span {
  font-size: 8px;
}
.ir-ws-active-status-icon {
  width: 16px;
  height: 16px;
}
.ir-ws-webinar-status-text {
  font-size: 8px;
}
.ir-ws-list-item-box{
font-size: 10px;
}
.ir-ws-webinar-list-accordian-tab {
  margin-top: 0px;
  height: 32px;
}
.ir-ws-webinar-list-data {
  font-size: 8px;
  height: 32px;
}
.ir-ws-webinar-download-icon {
  height: 16px;
}
}


@media (min-width:1280px) {
  .ir-ws-custom-tab-button {
    font-size: 14px;
  
  }
  .ir-ws-webinar-list-tab:last-child {
    margin-bottom: 10px;
  }
  .ir-ws-webinar-title-text {
    font-size: 12px ;
    margin-left: 10px;
  }
  .ir-ws-course-list-image {
    width: 24px !important;
    height: 24px !important;
  }
  .ir-ws-admin-webinar-content {
    padding: 6px 0px 6px 0px !important;
  }
  .ir-ws-admin-list-button-container button {
    font-size: 10px;
    width: 100px;
    margin-right: 10px;
    padding: 10px 0px;
  }

  .ir-ws-list-item-box {
    font-size: 12px;
  }
  .ir-ws-webinar-list-data li {
    font-size: 12px;
  }
  .ir-ws-webinar-list-tab {
    margin: 10px 10px 0px 10px;
    width: calc(100% - 20px);
  }

  .ir-ws-admin-webinar-title-container span {
    font-size: 10px;
  }
  .ir-ws-webinar-status-text {
    font-size: 10px;
  }
  .ir-ws-active-status-icon {
    width: 20px;
    height: 20px;
  }

  .ir-ws-webinar-list-accordian-tab {
    margin-top: 10px;
    height: 40px ;
  }
  .ir-ws-webinar-list-data {
    height: 40px ;
  }
  .ir-ws-webinar-download-icon {
    height: 25px;
  }
  .ir-customtabs-child-container .ir-found-no-results-text {
    font-size: 16px !important;
}
.chat-room-image-circle {
  width: 28px;
  height: 28px;}
}

@media (min-width:1700px) {
  .chat-room-image-circle {
    width: 32px;
    height: 32px;}
  .ir-ws-webinar-list-tab:last-child {
    margin-bottom: 20px;
  }
  .ir-ws-custom-tab-button {
    font-size: 16px;
  
  }
  .ir-ws-admin-webinar-content {
    padding: 10px 0px 10px 0px !important;
  }
  .ir-ws-webinar-title-text {
    font-size: 14px ;
  }
  .ir-ws-course-list-image {
    width: 30px !important;
    height: 30px !important;
  }

  .ir-ws-admin-list-button-container button {
    font-size: 13px;
    width: 135px;
  }
  .ir-ws-list-item-box {
    font-size: 14px;
  }
  .ir-ws-webinar-list-data li {
    font-size: 14px;
  }
  .ir-ws-webinar-list-tab {
    margin: 20px 20px 0px 20px;
    width: calc(100% - 40px);
  }
  .ir-ws-admin-webinar-title-container span {
    font-size: 14px;
  }
  .ir-ws-webinar-status-text {
    font-size: 14px;
  }
  .ir-ws-active-status-icon {
    width: 24px;
    height: 24px;
  }
  .ir-ws-webinar-list-accordian-tab {
    height: 50px ;
  }
  .ir-ws-webinar-list-data {
    height: 50px ;
  }
}