.ir-ws-side-menu {
    width: 80px;
    --webkit-box-orient: vertical;
    --webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    --webkit-box-pack: justify;
    -ms-flex-pack: justify;
    background: rgb(43,167,255);
    background: linear-gradient(360deg, rgba(75,113,247,1) 1%, rgba(96,79,241,1) 80%, rgba(104,66,239,1) 100%);
    --webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;

    .ir-ws-navbar-brand-box {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 10px;
        .logo {
            display: block;
            text-decoration: none;

            p {
                color: #ffffff;
                margin: 4px;
                font-size: 12px;
            }

            img {
                width: 65px;
                border-radius: 8px;
                padding: 0 5px;
                background: #fff;
                margin-top: 14px;
            }
        }
    }

    

    .side-menu-nav {
        display:var(--webkit-box-flex);
        display: var(--ms-flexbox);
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0;
        padding: 0 0 0 6px;
        flex-wrap: wrap;
        list-style: none;
        height: 70%;
        // background-color: red;
        margin-top: auto;

        .side-nav-item {
            position: relative;
            margin-bottom: 15px;
            &.activeMenuItem {
                &::before{
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    top: 100%;
                    right: 0;
                    height: 35px;
                    width: 35px;
                    border-top-right-radius: 18px;
                    box-shadow: 0 -20px 0 0 #fff;
                }

                &:after {
                    content: "";
                    position: absolute;
                    background-color: transparent;
                    bottom: 100%;
                    right: 0;
                    height: 35px;
                    width: 35px;
                    border-bottom-right-radius: 18px;
                    box-shadow: 0 20px 0 0 #fff;
                }
            }
            .nav-link {
                display: var(--webkit-box);
                display: var(--ms-flexbox);
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                padding: 15px 20px 15px 15px;
                position: relative;
                cursor: pointer;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 0;
                    height: 100%;
                    background-color: #fff;
                    border-radius: 40px 0 0 40px;
                    // transition: all 100ms ease-in-out;
                }
                &.active {
                    &:before {
                        width: 95%;
                    }
                }

                img {
                    width: 20px;
                    z-index: 1;

                    &.ir-chat-profile-pic {
                        width: 40px;
                        height: 40px;
                        border: 2px solid #fff;
                        border-radius: 50%;
                    }
                }

                &.user-img-replace-txt {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    text-align: center;

                    p {
                        margin: 0;
                        line-height: 35px;
                        background-color: #47bc94;
                        border-radius: 50%;
                        border: 2px solid #fff;
                        color: #ffffff;
                        font-family: Poppins-Bold;
                    }
                }
            }

            .ir-nav-tooltip {
                // display: none;
                position: absolute;
                top: 50%;
                left: 72px;
                text-align: left;
                z-index: 1;
                transform: translateY(-50%);
                background-color: rgba(0,0,0,0.8);
                color: #fff;
                padding: 1px 8px;
                border-radius: 5px;
                font-size: 12px;
                display: none;

                &:before {
                    content: '';
                    position: absolute;
                    top: 20%;
                    left: -11px;
                    width: 0;
                    height: 0;
                    border: 0.5em solid transparent;
                    border-right-color: rgba(0,0,0,0.8);
                }

            }
            &:hover {
                span {
                    display: block;
                }
            }

            &#Profile {
                padding: 10px;
                margin-top: auto;
                a {
                    padding: 0;
                    display: block;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        border: 2px solid #fff;
                        object-fit: cover;
                    }
                }

                &.activeMenuItem5 {
                    a {
                        border: 0;
                        border-radius: 0%;
                        background-color: transparent;
                        margin: auto;

                        &::before,
                        &::after {
                            content: none;
                        }
                    }
                }
            }
        }
    }
}

@-webkit-keyframes bell-ring {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    1% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg)
    }

    3% {
        -webkit-transform: rotate(-28deg);
        transform: rotate(-28deg)
    }

    5% {
        -webkit-transform: rotate(34deg);
        transform: rotate(34deg)
    }

    7% {
        -webkit-transform: rotate(-32deg);
        transform: rotate(-32deg)
    }

    9% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg)
    }

    11% {
        -webkit-transform: rotate(-28deg);
        transform: rotate(-28deg)
    }

    13% {
        -webkit-transform: rotate(26deg);
        transform: rotate(26deg)
    }

    15% {
        -webkit-transform: rotate(-24deg);
        transform: rotate(-24deg)
    }

    17% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg)
    }

    19% {
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg)
    }

    21% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    23% {
        -webkit-transform: rotate(-16deg);
        transform: rotate(-16deg)
    }

    25% {
        -webkit-transform: rotate(14deg);
        transform: rotate(14deg)
    }

    27% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }

    29% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg)
    }

    31% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg)
    }

    33% {
        -webkit-transform: rotate(6deg);
        transform: rotate(6deg)
    }

    35% {
        -webkit-transform: rotate(-4deg);
        transform: rotate(-4deg)
    }

    37% {
        -webkit-transform: rotate(2deg);
        transform: rotate(2deg)
    }

    39% {
        -webkit-transform: rotate(-1deg);
        transform: rotate(-1deg)
    }

    41% {
        -webkit-transform: rotate(1deg);
        transform: rotate(1deg)
    }

    43% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@keyframes bell-ring {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    1% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg)
    }

    3% {
        -webkit-transform: rotate(-28deg);
        transform: rotate(-28deg)
    }

    5% {
        -webkit-transform: rotate(34deg);
        transform: rotate(34deg)
    }

    7% {
        -webkit-transform: rotate(-32deg);
        transform: rotate(-32deg)
    }

    9% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg)
    }

    11% {
        -webkit-transform: rotate(-28deg);
        transform: rotate(-28deg)
    }

    13% {
        -webkit-transform: rotate(26deg);
        transform: rotate(26deg)
    }

    15% {
        -webkit-transform: rotate(-24deg);
        transform: rotate(-24deg)
    }

    17% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg)
    }

    19% {
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg)
    }

    21% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    23% {
        -webkit-transform: rotate(-16deg);
        transform: rotate(-16deg)
    }

    25% {
        -webkit-transform: rotate(14deg);
        transform: rotate(14deg)
    }

    27% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }

    29% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg)
    }

    31% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg)
    }

    33% {
        -webkit-transform: rotate(6deg);
        transform: rotate(6deg)
    }

    35% {
        -webkit-transform: rotate(-4deg);
        transform: rotate(-4deg)
    }

    37% {
        -webkit-transform: rotate(2deg);
        transform: rotate(2deg)
    }

    39% {
        -webkit-transform: rotate(-1deg);
        transform: rotate(-1deg)
    }

    41% {
        -webkit-transform: rotate(1deg);
        transform: rotate(1deg)
    }

    43% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

#Notifications {
    display: none;
}

@media (max-width: 768px) {

    .ir-ws-side-menu {
        display: none;
    }
  }
