.ir-ws-hme-webnr-inside-gradient-contianer {
  border-radius: 0px 48px 48px 48px;
  background: linear-gradient(
    270deg,
    rgba(243, 211, 228, 1) 0%,
    rgba(128, 133, 255, 0) 100%
  );
  padding: 4px;
  .ir-ws-hme-webinar-main-container {
    border-radius: 0px 48px 48px 48px;
    background: linear-gradient(
      270deg,
      rgb(230, 253, 252) 0%,
      rgba(128, 133, 255, 0) 100%
    );

    .ir-ws-hme-webinar-inner-container {
      padding-top: 50px;
    }
  }
}
.ir-ws-hme-upcoming-text {
  margin-bottom: 0px;
  font-size: 32px;
  font-weight: 500;
}
.ir-ws-hme-webinar-sessions-container {
  .ir-ws-hme-prvw-card-container {
    margin-bottom: 30px;
    .ir-ws-card-main-container {
      padding: 0;
      position: relative;

      .ir-ws-book-now-container {
        position: absolute;
        bottom: 20px;
        right: 20px;
        margin-top: 0;

        .ir-ws--create-webinar-submit-button {
          padding: 10px 15px;
          width: fit-content !important;
          height: fit-content;
        }
      }

      .ir-ws-webinar-card-thumbnail-container {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .ir-ws-hme-webinar-car-title-tag {
        font-size: 14px;
        // font-weight: 700;
        width: 120px;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.2px;
        border-radius: 3px;
        background: #c6c6c6;
        padding: 0 10px;
        top: 60px;
        // left: 15px;
        transform: rotate(-45deg);
        transform-origin: bottom left;
        text-align: center;
        // clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 50%);
        clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
        // clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
      }

      .ir-ws-card-body {
        padding: 20px 25px;

        .ir-ws-hme-webinar-tag-container {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          letter-spacing: 0.2px;
          background-color: #26335d;
          padding: 5px 10px;
          border-radius: 20px;
          display: inline-block;
        }

        .ir-ws-webinar-crd-bdy-title {
          // margin-top: 15px;
          font-weight: 700;
          line-height: 24px; /* 150% */
          letter-spacing: 0.1px;
          font-size: 16px;
        }

        .ir-ws-hme-webinar-crd-para {
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.2px;
          font-size: 16px;
          margin-top: 15px;
        }

        .ir-ws-hme-webinar-seats-container {
          margin-top: 15px;
          span {
            padding-left: 10px;
            font-weight: 500;
            line-height: 24px; /* 171.429% */
            letter-spacing: 0.2px;
            font-size: 14px;
          }

          img {
            width: 30px;
            height: 30px;
          }
        }

        .ir-ws-hme-webinar-price-container {
          margin-top: 20px;
          font-weight: 700;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.2px;
          font-size: 16px;
          color: #bdbdbd;

          span {
            padding-left: 8px;
            // color: #FF7B52;
          }

          img {
            width: 25px;
            height: 25px;
          }
        }

        .ir-ws-hme-webinar-lang-container {
          margin-top: 20px;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.2px;
          font-size: 14px;

          span {
            padding-left: 8px;
          }

          img {
            width: 25px;
            height: 25px;
          }
        }

        .ir-ws-hme-webinar-pro-container {
          margin-top: 15px;
          padding: 15px 0;

          img {
            padding-right: 8px;
          }

          span {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            letter-spacing: 0.2px;
          }
        }

        .ir-ws-hme-webinar-book-btn-container {
          margin-top: 10px;

          button {
            padding: 10px 20px;
            border: 1px solid #6842ef;
            border-radius: 38px;
            font-weight: 700;
            font-size: 14px;
            display: flex;
            align-items: center;
            transition: 0.25s;

            &:hover {
              color: #fff;
              box-shadow: inset 0 0 0 2em #6842ef;
            }

            img {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

.ir-ws-hme-webinar-content-container {
  .ir-ws-hme-webinar-content-title {
    font-size: 28px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    padding: 35px 0;

    &::after {
      position: absolute;
      content: "";
      width: 100px;
      top: 0;
      left: 0;
      height: 7px;
      background-color: #6842ef;
    }
  }

  .ir-ws-hme-webinar-content-para {
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
    font-size: 14px;
    margin-bottom: 35px;
  }

  .ir-ws-hme-webinar-scnd-title {
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.2px;
    color: #012572;
    font-size: 14px;
    margin-bottom: 30px;
    display: block;

    img {
      margin-left: 10px;
    }
  }

  .ir-ws-webinar-signup-btn {
    width: 320px;
    border-radius: 5px;
    padding: 15px 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.2px;
    position: relative;
    background-size: 400%;
    height: 52px;
    background-size: 400%;
    color: #fff;
    border: none;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: 0 50%;
      width: 100%;
      height: inherit;
      border-radius: inherit;
      background: rgb(43, 167, 255);
      background: linear-gradient(
        90deg,
        rgba(43, 167, 255, 1) 0%,
        rgba(104, 66, 239, 1) 100%
      );
      transition: all 0.8s;
    }

    &:hover::before {
      transform: scaleX(1);
    }

    span {
      position: relative;
      z-index: 1;
    }
  }

  .ir-ws-hme-prvw-signup-inner-container {
    margin-top: 35px;
  }
}
.ir-ws-hme-webinar-inner-container{
.ir-ws-hme-webinar-sessions-container{
  .slick-slider{
    .slick-list{
      .slick-track {
        .slick-slide{
padding: 0 5px;
        }
      }
    }
  }
}
}

@media (min-width: 768px) and (max-width: 990px) {
  .ir-ws-hme-webinar-sessions-container{
    .ir-ws-wrap{
      justify-content: space-between;
    }
  }
  .ir-ws-hme-webinar-sessions-container .ir-ws-hme-prvw-card-container .ir-ws-card-main-container .ir-ws-webinar-thumbnail-tablet {
    max-width: 229px;
  }
}
@media (min-width: 991px) {
  .ir-ws-hme-webinar-sessions-container {
    width: 100%;
    display: flex;
    .ir-ws-hme-prvw-card-container {
      padding-right: 35px;
    }
  }
}

@media (min-width: 1200px) {
  .ir-ws-hme-webnr-inside-gradient-contianer
    .ir-ws-hme-webinar-main-container
    .ir-ws-hme-webinar-inner-container {
    display: flex;
  }

  .ir-ws-hme-webinar-sessions-container {
    width: 65%;
    display: flex;
  }

  .ir-ws-hme-webinar-sessions-container .ir-ws-hme-prvw-card-container {
    padding-right: 35px;
  }

  .ir-ws-hme-webinar-sessions-container .ir-ws-hme-prvw-card-container {
    margin-bottom: 0;
  }

  .ir-ws-hme-webinar-content-container {
    width: 35%;
    padding-left: 50px;
  }
}
.ir-ws-book-now-text {
  text-align: center;
  //    font-weight: bold;
}
.ir-ws-book-now-container {
  button {
    width: 160px !important;
  }
  // width: 50% !important;
}

.ir-ws-webinar-status-tag-box {
  font-size: 12px;
  padding: 5px 0;
  font-weight: bold;
  color: #fff;
}
