.ir-ws-deleteModal-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 50px;
  @media (max-width: 720px) {
  //  margin-top: 30px;
  }
}

.ir-ws-webinar-book-verify-container
  .ir-ws-deleteModal-button-container
  .ir-ws-book-modal-btn {
  display: flex;
  justify-content: space-between;
}

.ir-ws-deleteModal-button-container button {
  border: none;
  width: 25%;
  height: 50px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  @media (max-width: 720px) {
    width: 100%;
    }
}

.ir-ws-deleteModal-button-yes {
  box-shadow: inset 0 0 0 2px #6842ef;
  background-color: #fff;
  color: #6842ef;
  transition: all 0.3s;
  &:hover {
    background-color: #6842ef;
    color: #fff;
  }
}
.ir-ws-deleteModal-button-no {
  box-shadow: inset 0 0 0 2px #6842ef;
  background-color: #fff;
  color: #6842ef;
  transition: all 0.3s;
  &:hover {
    background-color: #6842ef;
    color: #fff;
  }
}
@media (min-width:1024px) {
  
}