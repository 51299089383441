.favorite-cases-container {
  .favorite-cases-title-text {
    font-size: 24px;
    margin: 0;
    margin-block: 30px;
    font-weight: bold;
    color: #6842ef;
  }

  .favorite-cases-box {
    border: 1px solid #929292;
    width: 100%;

    .favorite-cases-title-container {
      list-style: none;
      margin: 0;
      background-color: #e9e9e9;
      border-bottom: 1px solid #929292;
      padding: 0;

      li {
        width: 300px;
        text-align: center;
        padding-block: 15px;
        text-transform: uppercase;
        color: #7e7e7e;
        font-weight: bold;
      }
    }

    .favorite-cases-details-container {
      overflow-x: scroll;
      height: 100%;
      max-height: 500px;

      .favorite-cases-details-box {
        border-bottom: 1px solid #929292;
        &:last-child {
          border-bottom: none;
        }

        .favorite-cases-title-details-container {
          background-color: #fff;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            width: 300px;
            text-align: center;
            padding-block: 15px;
            text-transform: uppercase;
            color: #7e7e7e;
            font-weight: bold;

            .accordian-dropdown-active {
              background-color: #6842ef;
              padding: 8px 48px;
              border-radius: 4px;
              cursor: pointer;

              .dropdown-icon {
                color: #fff;
                width: 24px;
              }
            }

            .accordian-dropdown-inactive {
              padding: 8px 48px;
              border-radius: 4px;
              background-color: #e9e9e9;
              cursor: pointer;
              transition: background-color 0.3s;

              &:hover {
                background-color: #6842ef;

                .dropdown-icon {
                  color: #fff;
                }
              }

              .dropdown-icon {
                width: 24px;
              }
            }
          }
        }

        .favorite-cases-accordian-details {
          background-color: #fff;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            width: 300px;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            color: #7e7e7e;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.ir-ws-no-favorite-cases-message {
  text-align: center;
  padding: 20px;

  width: 100%;
}
