.ir-ws-admin-create-webinar-tab {
  margin-top: 0px;
  height: 80vh;
  overflow-y: auto;
  background: rgba(220, 223, 227, 0.75);
  border-radius: 0;
  position: relative;
}
.ir-ws-create-webinar-button-container {
  display: flex;
  justify-content: center;
}

.ir-ws-create-webinar-button-container button {
  margin-top: 100px;
  border: none;
  width: 50%;
  height: 50px;
  font-size: 16px;
  border-radius: 50px;
  background-color: #ffffff;
  cursor: pointer;
}
.ir-ws-admin-webiner-list-container {
  // display: flex;
  flex-direction: column; /* This makes the elements stack vertically */
  align-items: flex-start;
}

.ir-ws-webinar-tb-main-container {
  height: calc(100% - 55px);
  border-radius: 0px 8px 8px 8px;
}

.ir-ws-create-btn-container {
  // margin-top: 16px;
  // animation: slideInUp; /* referring directly to the animation's @keyframe declaration */
  // animation-duration: 4s;

  .ir-ws-banner-btn {
    width: 320px;
    border-radius: 5px;
    padding: 15px 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.2px;
    position: relative;
    background-size: 400%;
    height: 52px;
    width: 50%;
    background-size: 400%;
    background-color: #6842ef;
    border: none;
    display: inline-block;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: 0 50%;
      width: 100%;
      height: inherit;
      border-radius: inherit;
      background: rgb(43, 167, 255);
      background: linear-gradient(
        90deg,
        rgba(43, 167, 255, 1) 0%,
        rgba(104, 66, 239, 1) 100%
      );
      transition: all 0.8s;
    }

    &:hover::before {
      transform: scaleX(1);
    }

    &:hover {
      text-decoration: none;
    }

    span {
      position: relative;
      z-index: 1;
    }
  }
}

@media (min-width:1024px) {
  
.ir-ws-webinar-tb-main-container {
  height: calc(100% - 45px);}
}

@media (min-width:1280px) {
  
  .ir-ws-webinar-tb-main-container {
    height: calc(100% - 55px);}
  }