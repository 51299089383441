.ir-ws-nav-main-container {
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 999;
  transition: box-shadow linear 500ms;
  // transition: all linear 300ms;

  &.ir-ws-nav-bg {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .ir-ws-logo-container {
    img {
      width: 100px;
    }
  }
  .ir-ws-nav-menu-ul {
    .ir-ws-nav-menu-list-item {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      .ir-ws-nav-menu-link {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.1px;
        transition: all 0.5s;
        padding-bottom: 5px;

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 0%;
          content: ".";
          color: transparent;
          background: #6842ef;
          height: 2px;
          transition: all 0.5s;
        }

        &:hover,
        &.active {
          text-decoration: none;
          color: #6842ef;
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  .ir-ws-nav-btn-ul {
    padding-left: 0;

    .ir-login-btn {
      width: 104px;
      //   border: 2px solid red;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 14px;
      position: relative;
      padding: 15px;
      margin-right: 10px;
      transition: all 0.4s;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        transition: all 0.6s;
        border-top-width: 2px;
        border-bottom-width: 2px;
        border-top-style: solid;
        border-bottom-style: solid;
        border-top-color: rgba(104, 66, 239, 0.5);
        border-bottom-color: rgba(104, 66, 239, 0.5);
        transform: scale(0.1, 1);
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: all 0.6s;
      }

      &:hover {
        letter-spacing: 5px;
        transition: all 0.6s;

        &:before {
          opacity: 1;
          transform: scale(1, 1);
          transition: all 0.6s;
        }

        &:after {
          opacity: 0;
          transform: scale(0.1, 1);
          transition: all 0.6s;
        }
      }
    }

    // .ir-ws-join-us-btn-container {
    // }
    .ir-ws-join-btn {
      border-radius: 5px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 22px;
      position: relative;
      transition: all 1s;

      &:hover {
        transform: scale(1.2, 1.2) translate3d(0, 0, 0);
      }

      img {
        margin-left: 15px;
      }
    }
  }

  .ir-ws-user-profile {
    // border: 2px solid yellow;
    gap: 4px;
    @media (max-width: 720px) {
      margin-bottom: 15px;
    }
    .ir-ws-verify-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      i {
        color: #6842ef;
      }
      span {
        color: #fcc419;
      }
    }
    .ir-ws-logged-username {
      font-size: 14px;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }

    .ir-ws-dropdown {
      cursor: pointer;

      i {
        padding-right: 0;
        padding-left: 8px;
        font-size: 12px;
      }
    }
  }

  .ir-ws-dropdown-content {
    display: none;
    background: #fff;
    padding: 15px 25px;
    position: absolute;
    width: 200px;
    right: 80px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    top: 75px;
    border: 1px solid #e0e0e1;

    &:before {
      background-color: #fff;
      border: 1px solid #ececee;
      border-radius: 0 0 0 0.25em;
      top: 6px;
      -webkit-clip-path: polygon(0 0, 100% 100%, 0 100%);
      clip-path: polygon(0 0, 100% 100%, 0 100%);
      content: "";
      height: 15px;
      left: 60%;
      margin-left: -15%;
      opacity: 1;
      position: absolute;
      transform: rotate(135deg) scaleY(1) translateY(1%);
      transform-origin: 50% 0;
      transition: opacity 0.2s ease;
      width: 15px;
      z-index: 101;
    }

    .ir-ws-dropdown-item {
      font-size: 13px;
      cursor: pointer;
      margin-bottom: 15px;

      &:hover {
        color: #6842ef;
      }

      span {
        padding-right: 5px;
        font-size: 18px;
      }
    }

    &.ir-ws-show-dropdown-menu {
      display: block;
    }
  }

  .ir-ws-nav-menu-list-container,
  .ir-ws-nav-sign-buttons {
    display: none;
  }
}

.ir-ws-nav-check {
  display: flex;
  flex-direction: column;
  width: 30px;
  cursor: pointer;

  span {
    display: inline-block;
    background: #000;
    border-radius: 10px;
    height: 4px;
    margin: 3px 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  span:nth-of-type(1) {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 75%;
  }

  .ir-ws-nav-check-label-span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(18px, -1px);
  }
  .ir-ws-nav-check-label-span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  .ir-ws-nav-check-label-span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(7px, -14px) rotatez(45deg);
  }
}

.ir-ws-nav-menu-mobile-block {
  height: 100vh;
  position: fixed;
  background-color: #fff;
  width: 100%;

  .ir-ws-nav-menu-mobile-black-bg {
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;

    .ir-login-btn {
      padding-left: 0;
      text-align: left;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .ir-ws-join-btn {
      padding: 15px;
      margin-bottom: 15px;
    }

    .ir-ws-nav-menu-ul {
      padding-left: 0;

      .ir-ws-nav-menu-list-item {
        margin-bottom: 15px;
        .ir-ws-nav-menu-link {
          font-size: 16px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .ir-ws-nav-check {
    display: none;
  }
  .ir-ws-nav-main-container {
    .ir-ws-nav-mobile-menu {
      display: none;
    }

    .ir-ws-nav-menu-list-container,
    .ir-ws-nav-sign-buttons {
      display: block;
    }

    .ir-ws-nav-menu-ul {
      padding-left: 10px;
    }

    .ir-ws-join-btn {
      padding: 10px;
    }
  }
}

@media (min-width: 991px) {
  .ir-ws-nav-main-container {
    .ir-ws-nav-menu-ul {
      padding-left: 150px;
    }

    .ir-ws-join-btn {
      padding: 15px 25px;
    }
  }
}

// @media (max-width: 768px) {

//   .ir-ws-nav-inner-container {
//     display: flex;
//   }

//   .ir-ws-header-mob-nav-menu-main-container {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }

//   .ir-ws-nav-menu-mobile-block {
//     position: fixed;
//     top: 0;
//     left: 0;
//     background-color: #fff;
//     height: 100%;
//   }

// }

.dark-mode {
  .ir-ws-nav-menu-link,
  .ir-ws-logged-username,
  .ir-ws-dropdown {
    color: #e3e3e3;
  }
}

.ir-ws-chat-home-nav{
  i{
    font-size: 30px;
    color: #252b42
  }
}



@media (min-width:1024px) {
  .ir-ws-nav-main-container .ir-ws-logo-container img {
    width: 70px;
  }

}


@media (min-width:1280px) {

  .ir-ws-nav-main-container .ir-ws-logo-container img {
    width: 80px;
  }

}

@media (min-width:1700px) {
  .ir-ws-nav-main-container .ir-ws-logo-container img {
    width: 100px;
  }
}