.ir-courses-single-page-main-container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(202, 187, 255, 1) 100%
  );
}
.ir-course-preview-top-container{
  padding-top: 75px;
}
.ir-courses-single-page-inner-container {
  position: relative;
  padding-top: 75px;
  // padding-top: 132px;

  .ir-course-preview-topic-text {
    font-weight: 500;
    font-size: 18px;
    // font-size: 14px;
    margin-top: 12px;
    margin-bottom: 20px;
    // text-align: center;
  }

  .ir-course-preview-created-text {
    margin-bottom: 10px;
    margin-top: 30px;
    font-weight: bold;
    font-size: 18px;
  }

  .ir-course-preview-rating-container {
    margin-top: 40px;
    // justify-content: center;
  }

  .ir-course-language-difficulty-update-container {
    .ir-course-language-container {
      p {
        margin-bottom: 0;
        padding-left: 15px;
      }
    }
  }
}
.ir-course-preview-detail-page-container {
  flex-direction: column-reverse;
}

.ir-course-preview-title-text,
.ir-course-preview-subtitle-text,
.ir-course-preview-course-description-text,
.ir-course-preview-created-text,
.ir-course-preview-created-description-text {
  // text-align: center;
}

.ir-single-course-buy-final-inner-container {
  flex-direction: column;
  gap: 30px;
}
.ir-course-language-difficulty-update-container {
  // flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
}
.ir-course-language-container {
}
.ir-course-preview-buy-course-box {
  .ir-course-preview-buy-course-text {
    .ir-ws--create-webinar-submit-button {
      width: 150px;
    }
  }
}
.ir-course-prview-video-player-buy-container {
  .ir-course-preview-course-button-container {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    i.ir-course-preview-btn {
      font-size: 60px;
      color: #6842ef;
      // color: #ffffff;
      opacity: 0.8;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    .ir-course-preview-course-button-box {
      text-align: center;
      line-height: normal;
    }

    p.ir-course-preview-course-text {
      margin-bottom: 0;
      // font-size: 22px;
      font-size: 14px;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.59);
    }
  }

  .ir-course-preview-primarily-taught-text {
    margin-bottom: 0;
    margin-top: 25px;
  }
}

.ir-course-preview-buy-course-box {
  .ir-course-preview-buy-course-text {
    margin-top: 0;
  }

  .ir-course-preview-heart-box {
    // margin-left: 20px;
    // margin-left: 20px;
    /* padding: 11px 18px; */
    background: #6842ef;
    line-height: normal;
    border-radius: 5px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      color: #fff;
      font-size: 24px;
      transition: all 0.3s ease;
    }

    &:hover i {
      transform: scale(1.2);
    }
  }
}

.ir-course-preview-buy-course-box {
  // justify-content: center;
  // justify-content: space-between;
  justify-content: center;
  gap: 10px;
}

.ir-single-course-buy-final-main-container {
  margin-top: 30px;
  // display: none;
}
.ir-course-preview-review-cont {
  visibility: hidden;
  .ir-course-preview-one-cont {
    p {
      font-size: 2rem;
      font-weight: 900;
      margin: 0;
      color: #fff;
      opacity: 50%;
    }
  }
}

.ir-course-preview-review-text-main-cont {
  position: absolute;
  width: 85.5%;
  bottom: -25px;
}
.ir-course-preview-review-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  p {
    font-size: 4rem;
    font-weight: 900;
    margin: 0;
    color: #fff;
    opacity: 50%;
  }
}

.ir-course-preview-learn-description-section {
  margin: 50px 0;
  // display: none;
  .ir-course-preview-learn-description-box {
    background-color: #d8ceff;
    border-radius: 10px;
    padding: 45px 20px;

    .ir-course-preview-learn-text {
      font-size: 24px;
      font-weight: 700;
      color: #6842ef;
      margin-bottom: 16px;
      text-align: center;
    }
    .ir-course-preview-learn-point-container {
      display: flex;
      align-items: center;
      justify-content: space-around;

      ul {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 75%;

        li {
          flex-basis: calc(50% - 30px);
          font-size: 18px;
          font-weight: 500;
          margin: 5px; /* Adjust margin as needed */
          box-sizing: border-box;
          margin-top: 20px;
          align-items: center;

          .ir-course-preview-list-item {
            margin-left: 20px;
          }

          .ir-ws-circle-icon i {
            font: 16px;
          }

          .ir-course-preview-list-item-icon i {
            font-size: 30px;
          }
        }
      }
      .ir-course-preview-course-includes-container {
        list-style: none;
        padding: 0;
        padding-inline: 10rem;
        column-gap: 20rem;
        li {
          i {
            margin-right: 10px;
          }
        }
      }
    }
    .ir-course-preview-course-includes-text {
      margin-top: 1rem;
    }
  }
}
// }
.ir-course-review-cancel-button-container {
  display: flex;
  // flex-direction: row;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 4rem;
}

.ir-ws-creeate-course-icon-margin {
  margin-right: 20px;
  margin-top: 14px;
}
.ir-ws-creeate-course-includes-icon-margin {
  margin-right: 20px;
  margin-top: 10px;
}
.ir-ws-course-preview-video-thumbnail {
  width: 100%;
  border-radius: 10px;
}
.ir-ws-preview-course-price-text {
  font-size: 1.2rem;
}

@media (min-width: 768px) {
  .ir-course-preview-detail-page-container
    .ir-course-prview-video-player-buy-container {
    margin-left: 0px;
    width: 100%;
  }

  .ir-courses-single-page-inner-container
    .ir-course-preview-detail-page-container
    .ir-course-preview-detail-container {
    width: 100%;
  }
  // .ir-single-course-buy-final-inner-container {
  //   flex-direction: row;
  // }
  .ir-single-course-buy-final-inner-container {
    flex-direction: column;
    .ir-course-language-difficulty-update-container {
      width: 100%;
    }
    .ir-course-preview-buy-course-box {
      width: 100%;
    }
  }
  .ir-course-preview-buy-course-box
    .ir-course-preview-buy-course-text
    .ir-ws--create-webinar-submit-button {
    width: 300px;
  }
  .ir-course-preview-detail-container p,
  .ir-single-course-buy-final-inner-container p,
  .ir-course-preview-primarily-taught-text {
    font-size: 18px;
  }
  .ir-ws-single-course-learn-point-container
    .ir-single-learn-point-box
    .ir-single-learn-point-list-text {
    font-size: 18px;
  }
}

// .ir-ws-create-course-g-font {

// }
.course-file {
  color: #fff;
  font-size: 18px;
}
.course-doc-download-icon {
  color: #424242;
  font-size: 18px;
  &:hover {
    color: #6842ef;
  }
}

/////Newly Added CSS////////////
.single-page-det-icon {
  // width: 30px;
  width: 20px;
}
.ir-ws-single-course-point-container {
  // padding-left: 92px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.ir-single-learn-point-text {
  // font-size: 25.6px;
  font-size: 25px;
  font-weight: 700;
  color: #6842ef;
  margin-bottom: 16px;
}
.ir-single-learn-point-box {
  margin-top: 25px;
  // margin-top: 15px;
  // margin-bottom: 25px;
  .ir-single-learn-point-list-text {
    margin: 0;
    // font-size: 18px;
    font-size: 16px;
    // font-size: 14px;
    color: #737373;
    display: flex;
    // align-items: center;
    .ir-ws-point-text {
      margin: 0;
    }
    i {
      margin-right: 10px;
      // margin-top: 3px;
    }
  }
}
.ir-ws-single-course-learn-point-container {
  // width: 50%;
  width: 100%;
}
.ir-ws-requirements-arrray-box {
  // margin-left: 52px;
}
.ir-single-requirement-point-box {
  margin-top: 10px;
  .ir-single-requirement-point-list-text {
    margin: 0;
    font-size: 18px;
    color: #737373;
    font-weight: 500;
    display: flex;
    // align-items: center;
    .bi-circle-fill {
      margin-right: 10px;
      font-size: 12px;
      margin-top: 5px;
    }
    .ir-ws-point-text {
      margin: 0;
      font-size: 16px;
    }
  }
}

// @media (min-width: 768px) {
//   // .ir-course-preview-detail-page-container {
//   //   flex-direction: row;
//   // }

// }

@media (min-width: 991px) {
  .ir-ws-course-primary-text-align {
    text-align: center;
  }
  .ir-course-preview-detail-page-container {
    flex-direction: row;
  }
  .ir-single-course-buy-final-inner-container {
    flex-direction: row;
  }
  .ir-course-preview-detail-page-container
    .ir-course-prview-video-player-buy-container {
    margin-left: 20px;
    width: 40%;
  }

  .ir-courses-single-page-inner-container
    .ir-course-preview-detail-page-container
    .ir-course-preview-detail-container {
    width: 60%;
  }

  .ir-ws-single-course-point-container {
    flex-direction: row;
    padding: 0 50px;
  }
  .ir-single-requirement-point-box {
    padding-left: 25px;
  }
  .ir-single-requirement-point-box
    .ir-single-requirement-point-list-text
    .ir-ws-point-text {
    margin: 0;
    font-size: 18px;
  }
  .ir-course-review-cancel-button-container {
    flex-direction: row;
  }
  .ir-single-course-buy-final-inner-container {
    // flex-direction: column;
    .ir-course-language-difficulty-update-container {
      width: 60%;
    }
    .ir-course-preview-buy-course-box {
      width: 30%;
    }
  }
  .ir-course-preview-buy-course-box
    .ir-course-preview-buy-course-text
    .ir-ws--create-webinar-submit-button {
    width: 150px;
  }
  .ir-course-preview-review-text-main-cont {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .ir-course-preview-buy-course-box
    .ir-course-preview-buy-course-text
    .ir-ws--create-webinar-submit-button {
    width: 200px;
  }
  .ir-course-preview-detail-page-container
    .ir-course-prview-video-player-buy-container {
    margin-left: 50px;
    width: 30%;
  }

  .ir-courses-single-page-inner-container
    .ir-course-preview-detail-page-container
    .ir-course-preview-detail-container {
    width: 60%;
  }
  .ir-course-preview-review-text-main-cont {
    width: 93%;
  }
}

@media (min-width: 1440px) {
  .ir-course-preview-buy-course-box
    .ir-course-preview-buy-course-text
    .ir-ws--create-webinar-submit-button {
    width: 250px;
  }
  .single-page-det-icon {
    width: 30px;
  }
  .ir-course-preview-review-text-main-cont {
    width: 85.5%;
  }
}

@media (max-width: 500px) {
  .ir-course-preview-buy-course-box .ir-course-preview-buy-course-text {
    width: 100%;
    .ir-ws-course-price-main-btn {
      width: 100%;
    }
  }

  .ir-course-preview-buy-course-box .ir-course-preview-heart-box {
    width: 80px;
  }
}
