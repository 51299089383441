.ir-ws-webinar-image-editor-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ir-ws-webinar-image-controls {
  position: absolute;
  margin-bottom: 100px;
  //   bottom: 20px;
  //   left: 50%;
  //   width: 50%;
  //   transform: translateX(-50%);
  //   height: 40px;
  //   display: flex;
  //   align-items: center;
}

.ir-ws-webinar-image-crop-container {
  height: 400px;
}
.ir-ws-webinar-image-editor-save-btn {
  position: absolute;

  margin-top: 10px;
  // padding: 8px 20px;
  // border: none;
  // // background: #6610f2;
  // color: #fff;
  // border-radius: 5px;
  // font-size: 16px;
}


.ir-ws-webinar-image-controls {
  display: flex;
  gap: 10px;
  cursor: pointer;

}

.ir-ws-zoom-button {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
  background-color: #d1d0d0;
  transition: filter 0.4s ease;
  // transition: background-color 0.4s ease;
  &:hover{
    // background: #6842EF;
    filter: invert(100%);
  }
}
.ir-ws-zoom-button-images{
height: 20px;
width: 20px;
// border-radius: 50%;

}





@media (min-width:1024px) {
  .ir-ws-webinar-image-crop-container {
    height: 280px;
  }
}



@media (min-width:1280px) {
  .ir-ws-webinar-image-crop-container {
    height: 350px;
  }
}




@media (min-width:1700px) {
  .ir-ws-webinar-image-crop-container {
    height: 400px;
  }
}

