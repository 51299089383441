.ph-box-shadow {
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
  }
  

  .ph-user-product-cont {
    max-height: 100%;
  
    overflow-y: scroll;
  }
  
  .ph-user-product-cont::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffff;
  }
  
  .ph-user-product-cont::-webkit-scrollbar {
    width: 3px;
    background-color: #ffff;
  }
  
  .ph-user-product-cont::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // background: #5d5b73;
  }
  
  .ir-ad-pn-height{
    height: calc(100% - 45px);
  }

  .ir-ad-pn-last-cont{
    height: 45%;
  }
  .ir-ad-pn-border{
    border: 1px solid #857D77;
    border-style: dashed;
  }
  @media (max-width: 500px) {
    .ir-se-admin-form-input {
      padding: 8px;
      font-size: 10px;
    }
  }

  @media (min-width: 768px) {
    .ir-se-admin-form-input {
      padding: 8px;
      font-size: 10px;
    }
  }
  @media (min-width: 1024px) {
    .ir-ad-pn-height{
      height: calc(100% - 28px);
    }
    
  .ir-ad-pn-last-cont{
    height: calc(45% - 18px);
  }

  .ir-se-admin-form-input {
    padding: 8px;
    font-size: 8px;
  }
  }
  @media (min-width: 1280px) {
    .ir-ad-pn-height{
      height: calc(100% - 36px);
    }
    
  .ir-ad-pn-last-cont{
    height: calc(45% - 18px);
  }
  .ir-se-admin-form-input {
    padding: 10px;
    font-size: 10px;
  }
  }
  @media (min-width: 1700px) {
    .ir-ad-pn-height{
      height: calc(100% - 46px);
    }
    .ir-ad-pn-last-cont{
      height: calc(45% - 12px);
    }
    .ir-se-admin-form-input  {
      padding: 12px;
      font-size: 14px;
    }
  }