.ir-courses-banner-main-container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 18%,
    rgba(216, 206, 255, 1) 100%
  );
  .ir-courses-banner-inner-container {
    display: block;
    padding-top: 75px;

    .ir-ws-courses-banner-title {
      margin-bottom: 0;
      max-width: 900px;

      text-align: center;
    }

    .ir-courses-banner-title {
      margin-top: 30px;
      max-width: 800px;
      // font-size: 18px;
      font-size: 14px;
      text-align: center;
    }
  }
}

.ir-courses-banner-search-inner {
  background: #fff;
  padding: 8px 15px;
  border-radius: 10px;
}

.ir-courses-banner-search-input {
  border: 0;
  font-size: 16px;
  width: 100%;
}

.ir-courses-banner-search-btn {
  border: 0;
  background: #6842ef;
  padding: 15px 30px;
  color: #fff;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
}

.ir-courses-banner-search-container {
  max-width: 860px;
  margin-top: 50px;
}

input.ir-courses-banner-search-input:focus-visible {
  border: 0;
  outline: 0;
}

.ir-courses-banner-search-btn-container {
  margin-top: 0;
  display: none;
}

.ir-courses-banner-search-btn-container .ir-courses-banner-search-btn {
  width: fit-content;
}

.ir-ws-course-home-search-bar-container {
  position: relative;
}
.ir-ws-course-home-search-container {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  margin-top: 10px;
  padding: 8px 15px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  .ir-ws-course-suggested-keywords-container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-block: 15px;

    .ir-ws-course-suggested-keywords-title {
      p {
        margin: 0;
        font-size: 1.1rem;
        font-weight: 500;
      }
      .ir-ws-course-suggested-author-name {
        font-size: 0.9rem;
        font-weight: 400;
      }
    }
  }
}
.ir-ws-course-suggested-img {
  width: 50px;
}

.ir-courses-banner-img-container {
  display: none;
}
.ir-course-banner-mobile-search-box {
  // padding: 13px 18px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6842ef;
  color: #fff;
  // border-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  position: absolute;
  right: 0px;
  font-size: 20px;
}
@media (min-width: 768px) {
  .ir-courses-banner-main-container {
    .ir-courses-banner-inner-container {
      display: flex;
      .ir-courses-banner-content-container {
        .ir-ws-courses-banner-title {
          text-align: start;
        }
        .ir-courses-banner-title {
          text-align: start;
          font-size: 18px;
        }
      }
    }

    .ir-courses-banner-img-container {
      display: block;
    }
  }
}
@media (max-width: 990px) {
.ir-cours-list-grid-content{
  height: 100px;
}
}
@media (min-width: 991px) {
  .ir-course-banner-mobile-search-box {
    display: none;
  }
  .ir-courses-banner-search-btn-container {
    margin-top: 0;
    display: block;
  }
}

// .ir-ws-course-spiral-img {
//   // width: 100%;
//   width: 600px;
//   position: absolute;
//   left: -110px;
//   top: -350px;
// }
// .ir-ws-course-doc-img {
//   position: absolute;
//   // width: 100%;
//   width: 300px;
//   right: 0px;
//   left: -10px;
//   top: -150px;
// }
