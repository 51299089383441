.ir-profile-main-container {
  padding-top: 85px;
  .ir-profile-inner-main-container {
    .ir-profile-head-title-container {
      margin-bottom: 30px;
    }

    .ir-profile-header-main-container {
      padding-top: 30px;
      padding-bottom: 150px;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 18%,
        rgba(201, 233, 255, 1) 100%
      );
      .ir-profile-head-card-main-container {
        background: rgb(176, 66, 255);
        background: linear-gradient(
          90deg,
          rgba(176, 66, 255, 0.6250875350140056) 1%,
          rgba(44, 167, 255, 0.4962359943977591) 100%
        );

        .ir-profile-head-card-inner-container {
          justify-content: space-between;
        }

        .ir-profile-head-details-container {
          bottom: 10px;
          left: 270px;

          .ir-profile-head-name {
            font-weight: bold;
            font-size: 22px;
            color: #fff;
            font-family: "Inter", sans-serif;
          }

          .ir-profile-head-email {
            font-weight: 400;
            font-size: 16px;
            color: #fff;
          }
        }

        .ir-profile-head-letter-container {
          .ir-profile-head-letter {
            color: rgba($color: #fff, $alpha: 0.3);
            font-weight: 700;
            font-size: 250px;
            line-height: 275px;
            font-family: "Inter", sans-serif;
          }
        }

        .ir-profile-pic-inner-container {
          top: 50%;
          left: 30px;
          background-color: #fff;
          padding: 10px;
          border-radius: 50%;
          .ir-profile-pic {
            max-width: 100%;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            object-position: center;
            object-fit: cover;
          }
        }
        .ir-ws-myprofile-pencil-container {
          // border: 1px solid #97d1ff;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          position: absolute;
          bottom: -75px;
          left: 120px;
          // background-color: #fff;

          .ir-ws-myprofile-input {
            width: 40px;
            height: 40px;
            display: none;
          }
          .ir-ws-profile-pic-label {
            width: 40px;
            height: 40px;
            cursor: pointer;

            .ir-ws-profile-pic-span {
              background: transparent !important;
              .ir-ws-myprofile-pencil-icon {
                font-size: 30px;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .ir-profile-info-details-container {
      flex-direction: column;
      flex: 1 1 300px;
    }

    .ir-profile-info-btn-container {
      align-self: flex-start;
      margin-top: auto;
    }

    .ir-profile-acc-details-main-container {
      display: flex;
      flex-direction: column;
    }
    .ir-profile-info-details-inner-container {
      justify-content: space-between;
      flex-direction: column;
      .ir-profile-info-heading {
        font-size: 22px;
        font-weight: bold;
      }

      .ir-profile-content-heading {
        color: #7e7e7e;
        font-size: 16px;
        margin: 20px 0 5px;
      }

      .ir-profile-content {
        font-size: 14px;
      }
    }
  }

  .ir-ws-transaction-inner-container {
    .ir-transactions-heading {
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 20px;
    }
    table {
      border: 1px solid #929292;
    }
    thead {
      background-color: #e9e9e9;
      th {
        text-transform: uppercase;
        color: #7e7e7e;
        text-align: center;
        padding: 15px 10px;
        border: 0;
        border-top: 1px solid #929292;
        border-bottom: 1px solid #929292;
      }
    }
    tbody {
      tr {
        td {
          text-align: center;
          font-weight: bold;
          text-transform: uppercase;
          color: #7e7e7e;
          padding: 15px 10px;
          border: 0;
          border-bottom: 1px solid #929292;
        }
      }
    }
  }

  .ir-profile-footer {
    padding: 50px 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 33%,
      rgba(201, 233, 255, 0.5) 100%
    );
  }
}

.ir-ws-profile-invoice-text {
  color: #6842ef;
}
// .ir-ws-profile-status-container {

//   border-radius: 5px;
//   p {
//     width: 300px;
//     padding: 15px 40px;
//     font-size: 14px;
//     height: 52px;
//     color: #fff;
//     text-align: center;

//     font-weight: 700;
//     margin: 0;
//   }
// }
.success-status-bg {
  background-color: #009406;
}
.pending-status-bg {
  background-color: #ffc048;
}
.cancel-status-bg {
  background-color: #e30000;
}

.success-status {
  color: #009406 !important;
}

.pending-status {
  color: #ffc048 !important;
}

.failed-status {
  color: #e30000 !important;
}

.ir-ws-profile-status-container {
  border-radius: 5px;
  // background-color: #7e7e7e;
  button {
    width: 300px;
    padding: 15px 40px;
    font-size: 14px;
    height: 52px;
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    margin: 0;
  }
  //   &.success-status {
  //     background-color: #009406;
  //   }

  //   &.pending-status {
  //     background-color: #ffc048;
  //   }

  //   &.cancel-status {
  //     background-color: #e30000;
  //   }
  // }
}

.mb-3r {
  margin-bottom: 3rem;
}

.ir-fav-case-buy-link {
  color: #007bff !important;
  text-decoration: none;
  cursor: pointer;
}

.ir-fav-case-buy-link:hover {
  text-decoration: underline;
  color: #155396 !important;
}

.ir-ws-my-profile-padding {
  padding: 0 50px;
}

.ir-ws-no-favorite-courses-message {
  text-align: center;
  padding: 20px;

  width: 100%;
}
@media (min-width:500px) {
  .ir-profile-main-container .ir-ws-transaction-inner-container thead th {
    font-size: 10px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container {
    flex-direction: row;
  }
  .ir-profile-main-container .ir-ws-transaction-inner-container tbody tr td {
    font-size: 10px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container .ir-profile-info-heading {
    font-size: 18px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container .ir-profile-content-heading {
    font-size: 12px;
    margin: 14px 0 5px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container .ir-profile-content {
    font-size: 10px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-header-main-container .ir-profile-head-card-main-container .ir-profile-head-details-container .ir-profile-head-name {
    font-size: 18px;
  }
  
.ir-profile-main-container .ir-profile-inner-main-container .ir-profile-header-main-container .ir-profile-head-card-main-container .ir-profile-head-details-container .ir-profile-head-email {
  font-size: 12px;
}
.ir-profile-main-container .ir-ws-transaction-inner-container .ir-transactions-heading {
  font-size: 18px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-title-text {
  font-size: 18px;
}
.favorite-cases-container .favorite-cases-title-text {
  font-size: 18px !important;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-title-container li {
  font-size: 10px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-details-container .ir-ws-purchase-history-details-box .ir-ws-purchase-history-title-details-container li {
  font-size: 10px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-details-container .ir-ws-purchase-history-details-box .ir-ws-purchase-history-accordian-title li {
  font-size: 10px;
  padding-top: 15px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-details-container .ir-ws-purchase-history-details-box .ir-ws-purchase-history-accordian-details li {
  font-size: 10px;
  padding-bottom: 15px;
}
.favorite-cases-container .favorite-cases-box .favorite-cases-title-container li {
  font-size: 10px;
}
.ir-ws-no-favorite-cases-message {
  font-size: 10px;
}
.ir-ws-no-favorite-courses-message {
  font-size: 10px;
}
}

@media (min-width:1024px) {

}

@media (min-width:1280px) {
  .ir-profile-main-container .ir-ws-transaction-inner-container thead th {
    font-size: 12px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container {
    // flex-direction: row;
  }
  .ir-profile-main-container .ir-ws-transaction-inner-container tbody tr td {
    font-size: 12px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container .ir-profile-info-heading {
    font-size: 20px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container .ir-profile-content-heading {
    font-size: 14px;
    margin: 16px 0 5px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container .ir-profile-content {
    font-size: 12px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-header-main-container .ir-profile-head-card-main-container .ir-profile-head-details-container .ir-profile-head-name {
    font-size: 20px;
  }
  
.ir-profile-main-container .ir-profile-inner-main-container .ir-profile-header-main-container .ir-profile-head-card-main-container .ir-profile-head-details-container .ir-profile-head-email {
  font-size: 14px;
}
.ir-profile-main-container .ir-ws-transaction-inner-container .ir-transactions-heading {
  font-size: 20px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-title-text {
  font-size: 20px;
}
.favorite-cases-container .favorite-cases-title-text {
  font-size: 20px !important;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-title-container li {
  font-size: 12px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-details-container .ir-ws-purchase-history-details-box .ir-ws-purchase-history-title-details-container li {
  font-size: 12px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-details-container .ir-ws-purchase-history-details-box .ir-ws-purchase-history-accordian-title li {
  font-size: 12px;
  padding-top: 20px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-details-container .ir-ws-purchase-history-details-box .ir-ws-purchase-history-accordian-details li {
  font-size: 12px;
  padding-bottom: 20px;
}
.favorite-cases-container .favorite-cases-box .favorite-cases-title-container li {
  font-size: 12px;
}
.ir-ws-no-favorite-cases-message {
  font-size: 12px;
}
.ir-ws-no-favorite-courses-message {
  font-size: 12px;
}
}
@media (min-width:1700px) {
  .ir-profile-main-container .ir-ws-transaction-inner-container thead th {
    font-size: 14px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container {
    // flex-direction: row;
  }
  .ir-profile-main-container .ir-ws-transaction-inner-container tbody tr td {
    font-size: 14px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container .ir-profile-info-heading {
    font-size: 22px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container .ir-profile-content-heading {
    font-size: 16px;
    margin: 18px 0 5px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-info-details-inner-container .ir-profile-content {
    font-size: 14px;
  }
  .ir-profile-main-container .ir-profile-inner-main-container .ir-profile-header-main-container .ir-profile-head-card-main-container .ir-profile-head-details-container .ir-profile-head-name {
    font-size: 22px;
  }
  
.ir-profile-main-container .ir-profile-inner-main-container .ir-profile-header-main-container .ir-profile-head-card-main-container .ir-profile-head-details-container .ir-profile-head-email {
  font-size: 16px;
}
.ir-profile-main-container .ir-ws-transaction-inner-container .ir-transactions-heading {
  font-size: 22px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-title-text {
  font-size: 22px;
}
.favorite-cases-container .favorite-cases-title-text {
  font-size: 22px !important;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-title-container li {
  font-size: 14px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-details-container .ir-ws-purchase-history-details-box .ir-ws-purchase-history-title-details-container li {
  font-size: 14px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-details-container .ir-ws-purchase-history-details-box .ir-ws-purchase-history-accordian-title li {
  font-size: 14px;
  padding-top: 30px;
}
.ir-ws-purchase-history-conatiner .ir-ws-purchase-history-box .ir-ws-purchase-history-details-container .ir-ws-purchase-history-details-box .ir-ws-purchase-history-accordian-details li {
  font-size: 14px;
  padding-bottom: 30px;
}
.favorite-cases-container .favorite-cases-box .favorite-cases-title-container li {
  font-size: 14px;
}
.ir-ws-no-favorite-cases-message {
  font-size: 14px;
}
.ir-ws-no-favorite-courses-message {
  font-size: 14px;
}
}