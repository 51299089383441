.ir-ws-case-non-paid-cont {
  width: 100%;
  height: 100vh;
  .ir-ws-case-non-paid-content-box {
    flex-direction: column;
    height: 100%;
    gap: 30px;
  }
}
.ir-ws-case-page-sign-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.ir-cs-cases-detail-page-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #424242;
  // border: 2px solid red;
  // height: 100vh;
  // width: 100vw;
  // overflow: auto !important;
  // overflow: hidden;
}

.ir-cs-cases-detail-page-content {
  // margin: 80px 0;
  width: 100%;
  // max-width: 1600px;
  // height: 100%;
  // height: 100vh;
  // width: 100vw;
  max-height: fit-content;
  // border: 2px solid rgb(255, 234, 0);
  // overflow: hidden;
}
.ir-ws-case-category-icon {
  width: 70px;

  // Mobile devices
  @media (max-width: 480px) {
    width: 40px;
  }

  // Tablets
  @media (min-width: 481px) and (max-width: 768px) {
    width: 40px;
  }

  // Small laptops (13-inch)
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 40px;
  }
  // Medium laptops (14-15 inch)
  @media (min-width: 1025px) and (max-width: 1440px) {
    width: 50px;
  }
}

.ir-ws-case-slide-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  // height: 100%;

  @media (max-width: 1120px) {
    margin-bottom: 20px;
    margin-top: 80px;
  }
  @media (max-width: 720px) {
    margin-bottom: 20px;
    margin-top: 50px;
  }
}
.ir-ws-case-dark-mode-cont {
  width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6842ef;
  border-radius: 10px;
  cursor: pointer;
  @media (max-width: 720px) {
    width: 40px;
    height: 40px;
  }
  img {
    @media (max-width: 720px) {
      height: 28px;
    }
  }
}
.ir-ws-case-slide-text {
  font-size: 24px;
  // color: #6842ef;
  color: #6842ef;
  font-weight: 700;
  // Mobile devices
  @media (max-width: 480px) {
    font-size: 16px;
  }

  // Tablets
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 18px;
  }

  // Small laptops (13-inch)
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 20px;
  }

  // Medium laptops (14-15 inch)
  @media (min-width: 1025px) and (max-width: 1440px) {
    font-size: 20px;
  }

  // Large monitors (16-inch laptops, desktops)
  @media (min-width: 1441px) {
  }
}
.ir-ws-case-head-deatil-cont {
  // border: 2px solid red;
  background: #e5e5e5;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;
  // margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  flex-direction: row;
  // @media (max-width: 1200px) {
  // margin-top: 80px;
  // gap: 10px;
  // margin-bottom: 60px;
  // padding: 10px;
  // padding-left: 30px;
  // align-items: start;
  // }

  // Mobile devices
  @media (max-width: 480px) {
    margin-top: 50px;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
    padding: 10px;
    padding-left: 10px;
    align-items: start;
  }

  // Tablets
  @media (min-width: 481px) and (max-width: 768px) {
    flex-direction: column;
    margin-top: 50px;
    gap: 10px;
    margin-bottom: 50px;
    padding: 10px;
    padding-left: 10px;
    align-items: start;
  }

  // Small laptops (13-inch)
  @media (min-width: 769px) and (max-width: 1024px) {
    margin-top: 60px;
    // border: 2px solid green;
  }

  // Medium laptops (14-15 inch)
  @media (min-width: 1025px) and (max-width: 1440px) {
    margin-top: 30px;
    padding: 6px;
    // border: 2px solid red;
  }

  // Large monitors (16-inch laptops, desktops)
  @media (min-width: 1441px) {
    padding: 15px;
  }

  .ir-ws-case-category-icon-cont {
    // border: 2px solid red;
    // width: 10%;
  }
  .ir-ws-case-category-title-cont {
    // width: 20%;
    // border: 2px solid red;
    // padding-top: 10px;
    display: flex;
    justify-content: center;
    // gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .ir-ws-case-category-description-cont {
    width: 75%;
    @media (max-width: 1100px) {
      width: 100%;
    }
  }
}
.ir-ws-case-title-text {
  font-weight: 600;
  // color: #424242;
  font-size: 28px;
  margin: 0;
  // Mobile devices
  @media (max-width: 480px) {
    font-size: 16px;
  }

  // Tablets
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 18px;
  }

  // Small laptops (13-inch)
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 20px;
  }
  // Medium laptops (14-15 inch)
  @media (min-width: 1025px) and (max-width: 1440px) {
    font-size: 22px;
  }
}

.ir-ws-case-description-text {
  font-size: 16px;
  // border: 2px solid red;
  @media (max-width: 1440px) {
    font-size: 14px;
  }
}
.ir-ws-case-category-icon-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ir-ws-case-category-text {
  font-size: 18px;
  font-weight: 500;
  color: #6842ef;
  margin-bottom: 0px;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
}

.ir-cs-content-main-container {
  // border: 2px solid red;
  display: flex;
  gap: 0px;
  width: 100%;
  // height: 100vh;
  // height: calc(100vh - 180px);
  // max-height: fit-content;
  flex-direction: row;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  @media (min-width: 2000px) {
    // height: calc(100vh - 180px);
    // height: 900px;
    // max-height: 1800px;
  }
}
.ir-cs-content-left-container {
  // border: 2px solid rgb(0, 255, 136);
  padding-right: 20px;
  height: 100%;
  // overflow: auto;
  width: 50%;
  @media (max-width: 1200px) {
    order: 2;
    width: 100%;
    margin-top: 40px;
    padding-right: 10px;
  }
  @media (max-width: 720px) {
    margin-top: 40px;
  }
}
.ir-cs-content-right-container {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  // border: 2px solid rgb(246, 255, 0);
  position: relative;
  // border: 2px solid red;
  // overflow: ;
  @media (max-width: 1200px) {
    order: 1;
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 720px) {
    order: 1;
    width: 90%;
    margin: 0 auto;
    height: 400px;
    // border: 2px solid red;
  }
}

.ir-cs-disuccion-preview-container {
  // width: 100%;
  // height: 650px;
  // max-height: 550px;
  background: #e5e5e5;
  // position: relative;
  // border: 2px solid rgba(201, 13, 13, 0.393);
  border-radius: 10px;
  // overflow: hidden;
  // padding: 4px;
  @media (max-width: 1200px) {
    // border: 2px solid red;
    // height: 350px;
    width: 100%;
    height: 60% !important;
  }
  @media (max-width: 720px) {
    width: 100%;
  }
  // @media (min-width: 1800px) {
  //   height: 80%;
  // }
}

.ir-ws-case-carousel-btn {
  position: absolute;

  i {
    padding: 10px 12px;
    border-radius: 50%;
    // background: #957bf4;
    transition: all 0.3s;
    color: #6842ef;
    cursor: pointer;
    font-size: 30px;
    font-weight: bold;
    &:hover {
      // background: #6842ef;
      // color: #fff;
    }
  }
}
.ir-ws-carousel-left-btn {
  top: 50%;
  left: 5px;
  @media (max-width: 720px) {
    top: 30%;
  }
}
.ir-ws-carousel-right-btn {
  top: 50%;
  right: 5px;
  @media (max-width: 720px) {
    top: 30%;
  }
}
.ir-ws-case-carosuel-dot-cont {
  text-align: center;
  margin-top: 10px;
}
.ir-ws-case-carousel-dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  // background-color: gray;
  border: 2px solid #6842ef;
  border-radius: 50%;
  cursor: pointer;
}
.ir-ws-case-carousel-dot-active {
  background: #6842ef;
}

.ir-cs-case-sections {
  // width: 100%;
  // width: 65%;
  position: relative;
  // border: 2px solid black;
  // height: 100%;
}

.ir-ws-case-accordion-cont {
  // border: 2px solid #ff0000;
  // height: 90%;
  // height: 590px;
  // max-width: 100%;
  border-radius: 10px;
  // overflow: auto;
  // margin: 10px 0;
  // margin-bottom: 20px;
  @media (max-width: 1200px) {
    // margin-bottom: 60px;
    // margin-top: 40px;
    // height: 90%;
  }
  .ir-ws-case-accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 10px 20px;
    padding: 20px 25px;
    cursor: pointer;
    background: #e5e5e5;
    // border-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .ir-ws-create-case-bulk-image-box {
    height: auto;
    width: 100%;
    // border: 1px solid #424242;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    // @media (max-width: 720px) {
    //   height: 250px;
    //   width: 250px;
    // }
  }
  .learning-point-img {
    // height: 350px;
    // width: 350px;
    // @media (max-width: 720px) {
    //   height: 250px;
    //   width: 250px;
    // }
    width: 100%;
  }
  .ir-ws-case-accordion-accordion-content {
    // max-height: 700px;
    // overflow: auto;
    // height: 100%;
    // height: 590px;
    // border: 2px solid red;
    // transition: max-height 0.3s ease-out;
    padding: 0 25px;
    // background: #e5e5e5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.ir-ws-case-accordian-inactive-border {
  border-radius: 10px;
}
.ir-ws-case-accordian-active-border {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ir-ws-case-slide-title-text {
  font-size: 18px;
  font-weight: 700;
  color: #6842ef;
}
.ir-cs-section-icon {
  font-size: 28px;
  color: #6842ef;
}
.ir-ws-case-content-box {
  padding-bottom: 20px;
}
.ir-ws-case-case-content-description {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.ir-ws-case-case-content-text {
  font-size: 18px;
  margin: 0;
}
.ir-cs-case-actions {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  width: 100%;

  .ir-cs-favorite-button,
  .ir-cs-buy-button,
  .ir-cs-share-button {
    // padding: 10px 20px;
    width: 50px;
    height: 50px;
    font-size: 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    @media (max-width: 720px) {
      // padding: 10px 20px;
      width: 35px;
      height: 35px;
      font-size: 18px;
    }
  }

  .ir-cs-favorite-button {
    // background-color: #6842ef;
    // color: #ffffff;
    background-color: #b3a1f5;
    color: #ffffff;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #6842ef;
    }
    &.active {
      // color: red;
      background-color: #6842ef;
    }
  }

  .ir-cs-buy-button {
    background-color: #b3a1f5;
    color: #ffffff;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #6842ef;
    }
  }

  .ir-cs-share-button {
    background-color: #b3a1f5;
    color: #ffffff;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #6842ef;
    }
  }
}
//////////////////SLide DropDown Styling//////////////////
.ir-ws-case-slide-drop-down-cont {
  width: 30px;
  height: 30px;
  background-color: #e5e5e5;
  border-radius: 10px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s;
  @media (max-width: 720px) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #6842ef;
    .ir-ws-case-dropdown-icon {
      color: #ffffff;
    }
  }
  .ir-ws-case-dropdown-icon {
    font-size: 18px;
    color: #6842ef;
  }
}
.ir-ws-case-slide-dropdown-main-cont {
  position: relative;
}
.ir-ws-case-dropdown-active-cont {
  position: absolute;
  left: 90px;

  top: 3px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  gap: 5px;
  @media (max-width: 720px) {
    top: 40px;
    left: 0;
  }
  li {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #e5e5e5;
    border-radius: 10px;

    color: #6842ef;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: #6842ef;
      color: #ffffff;
    }
    &.active {
      background-color: #6842ef;
      color: #ffffff;
    }
  }
}
.ir-ws-case-dropdown-slide-inactive {
  overflow: hidden;
  white-space: nowrap;
  transition: max-width 0.3s ease;

  max-width: 0;
}
.ir-ws-case-dropdown-slide-active {
  // max-width: 500px;
  gap: 10px;
  // width: 620px;
  // display: flex;
  // flex-wrap: wrap;
  // border: 2px solid red;
  // margin-bottom: 20px;

  // @media (max-width: 1200px) {
  //   width: 420px;
  // }
  // @media (max-width: 720px) {
  //   width: 320px;
  // }
}
/////////Image Sequence scss//////////////////

.ir-ws-case-imgae-btn-cont {
  position: absolute;
  bottom: 15px;
  // border: 2px solid red;
  // margin-top: -60px;
  // margin-bottom: 30px;
  z-index: 100;
  @media (max-width: 1200px) {
    // margin-top: -50px;
    // margin-bottom: 30px;
    bottom: -40px;
  }
  @media (max-width: 720px) {
    // margin-top: -45px;
    // margin-bottom: 20px;
    bottom: -60px;
  }
}
.ir-ws-case-image-seq-func-cont {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ir-ws-case-image-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c8c8c8;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  transition: background 0.3s;
  cursor: pointer;
  &:hover {
    background: #6842ef;
  }

  img {
    width: 28px;
  }
}
.ir-ws-case-image-btn-active {
  background: #6842ef;
}

.ir-ws-case-canvas-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  aspect-ratio: unset;
}

.multiple-question-data-main {
  // border: 2px solid red;
  .mul-question {
    font-weight: 600;
    font-size: 16px;
  }
}

.multiple-question-options-main {
  display: flex;
  gap: 40px;
  flex-direction: row;
  // border: 2px solid red;
  @media (max-width: 720px) {
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    gap: 0;
  }
  .multiple-question-options-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 20px;

    .mul-radio-answer {
      width: 16px;
      height: 16px;
      margin-bottom: 4px;

      &:checked {
        accent-color: #6842ef;
      }
    }
    .mul-options {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.questions-submit-btn {
  background: #6842ef;
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 240px;
  height: 54px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 500;
  transition: background 0.3s ease;

  &:hover {
    // background: #6842ef;
  }
  &:disabled {
    background-color: #b3a1f5;
    cursor: not-allowed;
  }
}
.abnormality-result-msg-container {
  font-size: 18px;
  font-weight: 600;
  @media (max-width: 720px) {
    font-size: 16px;
  }
}
.fib-question-data-main {
  // border: 2px solid red;
  .fib-question {
    font-weight: 600;
    font-size: 16px;
  }
  .fib-ans-input {
    padding: 0 15px;
    margin-top: 4px;
    display: block;
    color: #fff;
    color: #000000;
    border-radius: 4px;
    border: none;
    width: 240px;
    height: 54px;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    outline: none;
    &:focus {
      outline-color: #6842ef;
      outline-width: 1px;
      outline-style: solid;
    }
  }
}
.fib-main-conatiner {
  // border: 2px solid red;
  display: flex;
  gap: 20px;
  flex-direction: column;
  @media (max-width: 1200px) {
  }
}
.fib-result-img-con {
  position: relative;
  // border: 2px solid red;
}
.abnormality-question-data-main {
  position: relative;
  display: flex;
  flex-direction: column;
  .abnormality-question {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .abnormality-img {
    margin-bottom: 20px;
    user-select: none !important;
    // border: 2px solid red;
    // height: 300px;
    // width: 300px;
    @media (max-width: 720px) {
      width: 100%;
      height: 100%;
      margin-bottom: 0;
    }
  }
}

.discussion-title {
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
}
.discussion-description {
  font-weight: 500;
  font-size: 16px;
  // margin-bottom: 20px;

  margin-bottom: 0px;
}

.rapid-question-data-main {
  .rapid-question {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .rapid-content-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 720px) {
      // border: 2px solid red;
      flex-direction: column;
    }
    .rapid-content-container-left {
      .rapid-ans-input {
        display: block;
        color: #000000;
        border-radius: 4px;
        border: none;
        width: 240px;
        height: 54px;
        margin-bottom: 10px;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        padding: 0 15px;
        outline: none;
        &:focus {
          outline-color: #6842ef;
          outline-width: 1px;
          outline-style: solid;
        }
        &:disabled {
          background-color: #ffffff;
          cursor: not-allowed;
        }
      }
    }
  }
}
.ir-ws-case-rapid-submit-timer-cont {
  display: flex;
  align-items: end;
  justify-content: space-between;
  // border: 2px solid red;
  @media (max-width: 720px) {
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
  }
}

.rapid-btn-container {
}
.rapid-content-container-right {
  .rapid-time-countdown {
    color: #6842ef;
    font-weight: 800;
    font-size: 2.6rem;
    margin-top: -10px;
    text-align: center;
  }
  .rapid-start-btn {
  }
}
.ir-ws-case-jumbling-question-text {
  font-size: 16px;
  font-weight: 600;
}
.ir-ws-case-single-answery-type-dark-cont {
  gap: 20px;
  @media (max-width: 720px) {
    gap: 10px;
    margin-left: 10px;
  }
}

.ir-ws-case-single-box {
  background-color: #b3a1f5;
  padding: 8px 16px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #fff;
  cursor: pointer;
}
.ir-ws-case-group-box {
  padding: 8px 16px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  cursor: pointer;
}
.ir-ws-inactive-type-question {
  background-color: #b3a1f5;
}
.ir-ws-active-type-question {
  background-color: #6842ef;
}

////////////Answe correct text css//////////////////
.result-message {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  // margin-top: -10px;
  margin-bottom: 20px;
  // font-size: 18px;
  width: 70%;
  gap: 30px;
  @media (max-width: 576px) {
    //  border: 2px solid red;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
    align-items: flex-start;
    //  width: 240px !important;
    //  margin: 0 auto;
  }
}

.your-answer {
  font-weight: 500;
  font-style: 18px;
  @media (max-width: 720px) {
    font-size: 16px;
  }
}

.your-answer.correct {
  color: green;
}

.your-answer.wrong {
  color: red;
}

.correct-answer {
  color: #6842ef;
  font-weight: 500;
  font-style: 18px;
  @media (max-width: 720px) {
    font-size: 16px;
  }
}

.ir-ws-single-case-jumbling-main-cont {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 720px) {
    flex-direction: column;
    gap: 20px;
  }
}
.ir-ws-jumbling-correct-pair-text {
  font-size: 18px;
  color: #6842ef;
  font-weight: 600;
  margin-left: 10px;
  @media (max-width: 720px) {
    font-size: 16px;
  }
}
.ir-ws-jumbling-correct-answr-text {
  font-size: 18px;
  font-weight: 600;
  // color: #6842ef;
  margin-left: 10px;
  @media (max-width: 720px) {
    font-size: 16px;
  }
}

.separation-line {
  width: 1px;
  height: 80px;
  background-color: #252b42;
  @media (max-width: 1200px) {
    display: none;
  }
}

.ir-ws-case-disabled-toggle {
  pointer-events: none;
  opacity: 0.5;
}

.ir-ws-case-slide-title-text {
  p {
    margin-bottom: 0px;
  }
}

// .ir-ws-single-case-pagination-cont {
//   position: absolute;
//   top: 520px;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }
.finish-btn-group {
  margin-top: 0;
  @media (max-width: 720px) {
    width: 100%;
  }
}
.ir-ws-single-case-pagination-cont {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  width: inherit;

  align-items: center;
  flex-wrap: wrap;
}
.ir-ws-single-case-pagination {
  display: flex;
  align-items: center;
  justify-content: start;

  @media (max-width: 720px) {
    justify-content: start;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}
.ir-ws-single-case-component-cont {
  // height: 79%;
  // overflow: scroll;
  // max-height: 500px;
  // overflow: auto;
  border-radius: 10px;
}
.ir-ws-single-case-component-active-question {
  height: 100%;
  // border: 2px solid red;
}
.ir-ws-single-case-prev-btn,
.ir-ws-single-case-next-btn {
  padding: 10px 20px;
  // background-color: #6842ef;
  background-color: #b3a1f5;
  color: #ffffff;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #6842ef;
  }
  margin-left: 15px;
  margin-right: 15px;
  // border: 2px solid red;

  @media (max-width: 480px) {
    padding: 5px 10px;
  }

  // Tablets
  @media (min-width: 481px) and (max-width: 768px) {
    padding: 5px 10px;
  }

  // Small laptops (13-inch)
  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 5px 10px;
  }
}

.ir-ws-single-case-page-inactive-slide-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #6842ef;
  color: #b3a1f5;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  p {
    margin-bottom: 0px;
  }
  cursor: pointer;

  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
  }

  // Tablets
  @media (min-width: 481px) and (max-width: 768px) {
    width: 25px;
    height: 25px;
  }

  // Small laptops (13-inch)
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 30px;
    height: 30px;
    // border: 2px solid red;
  }
}

.ir-ws-single-case-page-active-slide-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #6842ef;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  p {
    margin-bottom: 0px;
  }
  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
  }

  // Tablets
  @media (min-width: 481px) and (max-width: 768px) {
    width: 25px;
    height: 25px;
  }

  // Small laptops (13-inch)
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 30px;
    height: 30px;
    // border: 2px solid red;
  }
}

@media (max-width: 720px) {
  .ir-ws-single-case-pagination {
    // flex-direction: row;
    // order: 1;
  }

  .ir-ws-single-case-prev-btn,
  .ir-ws-single-case-next-btn {
    margin: 10px 0;
  }

  .ir-ws-single-case-page-inactive-slide-number,
  .ir-ws-single-case-page-active-slide-number {
    margin: 5px 0;
  }
}

.dark-mode {
  .ir-cs-cases-detail-page-main-container {
    background: #262626 !important;
  }
  background-color: #262626 !important;
  // color: #e3e3e3;
  .header-single-case {
    background-color: #262626;
  }

  .ir-ws-case-title-detail-cont {
    background-color: #1d1d1d !important;
  }
  .ir-ws-case-head-deatil-cont {
    background: #1d1d1d;
  }

  .ir-cs-disuccion-preview-container {
    background: #1d1d1d;
  }
  .ir-ws-case-accordion-header {
    background: #1d1d1d;
  }

  .ir-ws-case-accordion-cont .ir-ws-case-accordion-accordion-content {
    background: #1d1d1d;
    color: #e3e3e3;
  }
  .ir-cs-cases-detail-page-main-container {
    color: #262626;
    // border: 2px solid red;
  }

  .ir-ws-case-head-deatil-cont .ir-ws-case-category-title-cont {
    color: #e3e3e3;
  }

  .ir-ws-case-description-text {
    color: #a0a0a0;
  }
  .ir-ws-case-dropdown-active-cont li {
    background: transparent;
    color: #b3a1f5;
    &.active {
      background: #6842ef;
      color: #fff;
    }
  }
  .ir-ws-case-accordion-cont {
    background: #1d1d1d !important;
  }

  .ir-cs-content-right-container {
    background: #1d1d1d !important;
  }
  .case-question-main-container {
    // background: #1d1d1d;
    // border: 15px;
  }

  .multiple-question-options-main
    .multiple-question-options-content
    .mul-options {
    color: #a0a0a0;
  }
}

//**************************************//
.ir-cs-cases-detail-page-main-container {
  // border: 2px solid red;

  height: 100vh;
  width: 100vw;
  @media (max-width: 1200px) {
    overflow: hidden !important;
    height: fit-content;
  }
  .ir-cs-cases-detail-page-content {
    height: calc(100% - 60px);
    width: 100%;
    // overflow: hidden;
    // border: 2px solid red;
    // margin: 40px;
    // @media (max-width: 1400px) {
    //   height: calc(100% - 100px);
    //   border: 2px solid red;
    // }
    .ir-ws-case-title-detail-cont {
      // border: 2px solid red;
      .ir-ws-case-head-deatil-cont {
      }
    }
    .ir-cs-content-main-container {
      // border: 2px solid red;
      height: 85%;
      @media (min-width: 2000px) {
        // height: calc(100vh - 180px);
        height: 90%;
        // max-height: 1800px;
      }

      .ir-cs-content-left-container {
        // border: 2px solid green;
        .ir-ws-case-slide-cont {
          // height: 100%;
          // border: 2px solid red;
          height: 80px;
          // Mobile devices
          @media (max-width: 480px) {
          }

          // Tablets
          @media (min-width: 481px) and (max-width: 768px) {
          }

          // Small laptops (13-inch)
          @media (min-width: 769px) and (max-width: 1024px) {
            // border: 2px solid green;
          }

          // Medium laptops (14-15 inch)
          @media (min-width: 1025px) and (max-width: 1440px) {
            // border: 2px solid red;
            // height: 60px;
          }

          // Large monitors (16-inch laptops, desktops)
          @media (min-width: 1441px) {
          }
        }
        .case-question-main-container {
          height: calc(100% - 80px);
          // border: 2px solid red;
          .ir-ws-single-case-component-cont {
            // border: 2px solid blue;
            height: 100%;
            .ir-ws-case-accordion-cont {
              // background: #1d1d1d;
              // border: 2px solid yellow;
              // height: calc(100% - 70px);
              height: 100%;
              background: #e5e5e5;
              overflow-y: auto;

              .ir-ws-case-accordion-header {
                // background: #1d1d1d;
                // border: 2px solid rgb(0, 255, 21);
                // height: 20%;
              }
              .ir-ws-case-accordion-accordion-content {
                // border: 2px solid purple;
                // overflow: scroll;
                // height: 100%;
              }
            }
          }
        }
      }

      //* RIGHT SECTION
      .ir-cs-content-right-container {
        // border: 2px solid yellow;
        margin-bottom: 10px;
        height: 100%;
        background: #e5e5e5;
        border-radius: 15px;
        overflow: hidden;
        .ir-cs-disuccion-preview-container {
          // border: 2px solid red;
          // width: 100%;
          height: 100%;
        }
        .ir-ws-case-imgae-btn-cont {
          // border: 2px solid red;
        }
        .ir-cs-case-actions {
          // margin-top: 90px;
          // border: 2px solid red;
          margin-bottom: -110px !important;
        }
      }
    }
  }
}

// Mobile devices
@media (max-width: 480px) {
  /* styles for mobile devices */
}

// Tablets
@media (min-width: 481px) and (max-width: 768px) {
  /* styles for tablets */
}

// Small laptops (13-inch)
@media (min-width: 769px) and (max-width: 1024px) {
  /* styles for small laptops */
}

// Medium laptops (14-15 inch)
@media (min-width: 1025px) and (max-width: 1440px) {
  /* styles for medium laptops */
}

// Large monitors (16-inch laptops, desktops)
@media (min-width: 1441px) {
  /* styles for large monitors */
}

.ir-ws-single-case-pagination-cont {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  width: inherit;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ir-ws-single-case-pagination {
  display: flex;
  align-items: center;
  justify-content: start;

  @media (max-width: 720px) {
    justify-content: start;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}

.pagination-dots {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
  color: #b3a1f5;
}

.ir-ws-single-case-prev-btn,
.ir-ws-single-case-next-btn {
  padding: 10px 20px;
  background-color: #b3a1f5;
  color: #ffffff;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #6842ef;
  }

  margin-left: 15px;
  margin-right: 15px;

  @media (max-width: 480px) {
    padding: 5px 10px;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    padding: 5px 10px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 5px 10px;
  }
}

.ir-ws-single-case-page-inactive-slide-number,
.ir-ws-single-case-page-active-slide-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #6842ef;
  color: #b3a1f5;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;

  p {
    margin-bottom: 0px;
  }

  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    width: 25px;
    height: 25px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 30px;
    height: 30px;
  }
}

.ir-ws-single-case-page-active-slide-number {
  background-color: #6842ef;
  color: #fff;
  font-weight: 700;
}
